/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { PzApiEmptyReqData, PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import type { PzApiKratosReqData, PzApiKratosResData } from './authBaseApiDefs'
import { pzApiAuthSlug } from './authBaseApiDefs'

// -------------------------------------------------------------------

const pzApiLoginSlug = 'login'

// -------------------------------------------------------------------

export interface PzApiAuthPostLoginReqData extends PzApiKratosReqData {
  identifier: string
  password: string
}

// -------------------------------------------------------------------

interface PzApiAuthLoginResDataSuccess extends PzApiResDataSuccess {
  identityId: UuidB62
}

interface PzApiAuthLoginResDataError extends PzApiResDataFail {
  details?: {
    code: string
    message: string
    field?: string
    originalError?: any
  }
}

// -------------------------------------------------------------------

export type PzApiAuthLoginResData =
  | PzApiAuthLoginResDataSuccess
  | PzApiAuthLoginResDataError

// -------------------------------------------------------------------

export const pzApiPrepareLoginDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiLoginSlug}`,
  fetchMethod: 'GET',
  includeContext: true,
  skipAuthCheck: true,
  reqData: {} as PzApiEmptyReqData,
  resData: {} as PzApiKratosResData
} satisfies PzApiRoute<PzApiEmptyReqData, PzApiKratosResData>

// -------------------------------------------------------------------

export const pzApiPostLoginDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiLoginSlug}`,
  fetchMethod: 'POST',
  includeContext: true,
  skipAuthCheck: true,
  reqData: {} as PzApiAuthPostLoginReqData,
  resData: {} as PzApiAuthLoginResData
} satisfies PzApiRoute<PzApiAuthPostLoginReqData, PzApiAuthLoginResData>
