<script lang="ts">
  import { cn } from '../utils/shadcnUtils.ts'

  // -------------------------------------------------------------------

  interface Tab {
    id: string
    label: string
  }

  // -------------------------------------------------------------------

  const {
    activeId,
    tabs,
    tabClick,
    ...props
  }: {
    activeId: string
    tabs: Tab[]
    tabClick: (_tabId: string) => void
    class?: string
  } = $props()

  // -------------------------------------------------------------------

  const className: string = typeof props.class === 'string' ? props.class : ''
</script>

<!-- ------------------------------------------------------------- -->

<div class={cn('flex gap-1 rounded-t-3xl bg-pz_gray-bright p-2 pl-7', className)}>
  {#each tabs as tab (tab.id)}
    <button
      class="z-tab-button relative rounded-[8px] px-4 py-2 text-base transition-colors {activeId === tab.id
        ? 'bg-white text-primary'
        : 'text-gray-600 hover:bg-white hover:text-gray-900'}"
      onclick={() => tabClick(tab.id)}
    >
      {tab.label}
    </button>
  {/each}
</div>
