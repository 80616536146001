// import { t } from 'i18next'

import {
  ANONYMOUS_USER_KEY,
  AUTH_STATE_ANONYMOUS,
  AUTH_STATE_CHECKING_AUTH_STATE,
  AUTH_STATE_ERROR,
  AUTH_STATE_INITIAL,
  AUTH_STATE_LOGGING_IN,
  AUTH_STATE_LOGGING_OUT,
  AUTH_STATE_NOT_LOGGED_IN,
  AUTH_STATE_REGISTERING,
  AUTH_STATE_SIGNED_IN,
  AUTH_STATE_SIGNED_OUT,
  HAS_REGISTERED_USER_KEY,
  KRATOS_TO_ANONYMOUS_PREFIX
} from '@/constants/appStateConstants.ts'
import {
  pzApiSrvLogin,
  pzApiSrvLoginPrepare,
  pzApiSrvLogout,
  pzApiSrvRegistration,
  pzApiSrvRegistrationPrepare,
  pzApiSrvUpdateUserData,
  pzApiSrvWhoAmI
} from '@/services/pzApiSrv.ts'
import { timerService } from '@/services/timerService.ts'
import { log } from '@/utils/debug.ts'
import { newUuidB62, type UuidB62 } from '@/utils/id.ts'

import { injectState } from './appState.svelte.ts'
import { type UseStateReturn } from './appState.svelte.ts'

import type { PzApiResData } from '../../../srv/bun/src/apiDefs/apiDefs.ts'

// -------------------------------------------------------------------

export interface KratosUser {
  id: UuidB62
  email?: string
  displayName?: string
  isAnonymous?: boolean
  localDbId?: UuidB62
}

// -------------------------------------------------------------------

export interface AnonymousUser {
  id: UuidB62
  isAnonymous: true
  createdAt: number // timestamp
}

// -------------------------------------------------------------------

export interface AuthState {
  authState: AuthStateType
  authStateError: string
  authenticatedUser: KratosUser | undefined
  authenticatedUserId: UuidB62 | undefined
  localDbId: UuidB62 | undefined
  isLoggedInAndIdKnown: boolean
  isAnonymousUser: boolean
}

// -------------------------------------------------------------------

type AuthStateType =
  | typeof AUTH_STATE_INITIAL
  | typeof AUTH_STATE_CHECKING_AUTH_STATE
  | typeof AUTH_STATE_NOT_LOGGED_IN
  | typeof AUTH_STATE_LOGGING_IN
  | typeof AUTH_STATE_REGISTERING
  | typeof AUTH_STATE_LOGGING_OUT
  | typeof AUTH_STATE_ERROR
  | typeof AUTH_STATE_ANONYMOUS
  | typeof AUTH_STATE_SIGNED_IN
  | typeof AUTH_STATE_SIGNED_OUT

let authState: AuthStateType = $state(AUTH_STATE_INITIAL)

let authStateError: string = $state('')

let authenticatedUser: KratosUser | undefined = $state()

let userSubscriptionStarted = $state(false)

// -------------------------------------------------------------------

function hasRegisteredUser (): boolean {
  const registeredUserKey = HAS_REGISTERED_USER_KEY
  return localStorage.getItem(registeredUserKey) === 'true'
}

// -------------------------------------------------------------------

function markRegisteredUserExists (): void {
  const registeredUserKey = HAS_REGISTERED_USER_KEY
  localStorage.setItem(registeredUserKey, 'true')
}

// -------------------------------------------------------------------
// Creates or retrieves an anonymous user from localStorage
// -------------------------------------------------------------------

function getOrCreateAnonymousUser (): AnonymousUser {
  const storageKey = ANONYMOUS_USER_KEY
  const storedUser = localStorage.getItem(storageKey)

  if (storedUser) {
    try {
      return JSON.parse(storedUser) as AnonymousUser
    } catch (e) {
      // Invalid stored user, create a new one
      log(0, 'Invalid anonymous user in localStorage, creating new one', e)
    }
  }

  // Create new anonymous user
  const anonymousUser: AnonymousUser = {
    id: newUuidB62(),
    isAnonymous: true,
    createdAt: Date.now()
  }

  localStorage.setItem(storageKey, JSON.stringify(anonymousUser))
  return anonymousUser
}

// -------------------------------------------------------------------

export function useAuth (): UseStateReturn {
  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      void checkAuthState()
    },

    extendState (): ReturnType<UseStateReturn['extendState']> {
      injectState(
        'authState',
        () => authState,
        (val: AuthStateType) => {
          authState = val
        }
      )

      injectState(
        'authStateError',
        () => authStateError,
        (val: string) => {
          authStateError = val
        }
      )

      injectState(
        'authenticatedUser',
        () => authenticatedUser,
        (val: any) => {
          authenticatedUser = val
        }
      )

      injectState('authenticatedUserId', () =>
        (authenticatedUser?.id ?? '').length > 0 ? authenticatedUser?.id : undefined
      )

      injectState('localDbId', () =>
        authenticatedUser?.localDbId ?? authenticatedUser?.id
      )

      injectState('isAnonymousUser', () =>
        authState === AUTH_STATE_ANONYMOUS && authenticatedUser?.isAnonymous === true
      )

      injectState('isLoggedInAndIdKnown', () =>
        (authState === AUTH_STATE_SIGNED_IN || authState === AUTH_STATE_ANONYMOUS) &&
        authenticatedUser?.id !== undefined
      )
    }
  }
}

// -------------------------------------------------------------------

function setupAuthenticatedUser (kratosUser: any): void {
  markRegisteredUserExists()

  // Get the anonymous user to ensure data continuity
  const anonymousUser = getOrCreateAnonymousUser()
  const anonymousUserId = anonymousUser.id

  authState = AUTH_STATE_SIGNED_IN
  log(4, 'authState', authState)

  const authenticatedUserIdB62 = kratosUser.id

  authenticatedUser = {
    ...kratosUser,
    localDbId: anonymousUserId, // Use anonymous ID for local DB
    id: authenticatedUserIdB62
  }

  // Store the mapping between Kratos ID and anonymous ID
  const mappingKey = `${KRATOS_TO_ANONYMOUS_PREFIX}${authenticatedUserIdB62}`
  localStorage.setItem(mappingKey, anonymousUserId)
}

// -------------------------------------------------------------------

function setupUnauthenticatedUser (): void {
  // Check if there's a registered user in the system
  const registeredUserExists = hasRegisteredUser()

  if (registeredUserExists) {
    // If there's a registered user, set state to not logged in to show login form
    authState = AUTH_STATE_NOT_LOGGED_IN
    log(4, 'authState', authState)

    authenticatedUser = undefined
  } else {
    setupAnonymousUser()
  }
}

// -------------------------------------------------------------------

function setupAnonymousUser (): void {
  const anonymousUser = getOrCreateAnonymousUser()
  const anonymousUserId = anonymousUser.id

  authState = AUTH_STATE_ANONYMOUS
  log(4, 'authState', authState)

  authenticatedUser = {
    id: anonymousUserId,
    localDbId: anonymousUserId,
    isAnonymous: true
  }
}

// -------------------------------------------------------------------

async function checkAuthState (): Promise<void> {
  authState = AUTH_STATE_CHECKING_AUTH_STATE
  log(4, 'authState', authState)

  const kratosUser = await pzApiSrvWhoAmI()

  if (kratosUser != null) {
    setupAuthenticatedUser(kratosUser)
  } else {
    setupUnauthenticatedUser()
  }
}

// -------------------------------------------------------------------

export async function initiateSignup (): Promise<PzApiResData> {
  return await pzApiSrvRegistrationPrepare()
}

// -------------------------------------------------------------------

export async function signup ({
  email,
  password,
  name,
  flowId,
  csrfToken
}: {
  email: string
  password: string
  name: string
  flowId: string
  csrfToken: string
}): Promise<any | { errorMsg: string, loginErrMsg?: string }> {
  authState = AUTH_STATE_REGISTERING
  log(4, 'authState', authState)

  const data = await pzApiSrvRegistration({
    email,
    name,
    password,
    flowId,
    csrfToken
  })

  const result = await extractLoginOrRegistrationDataFromResponse(data)

  // If registration was successful, remove the anonymous user data
  if (result.ok === true) {
    // Remove the anonymous user to ensure data isolation
    localStorage.removeItem(ANONYMOUS_USER_KEY)
  } else {
    // Set error state and error message for registration failures
    authState = AUTH_STATE_ERROR
    authStateError = result.errorMsg ?? 'Registration failed'
    log(0, 'signup authState', authState, authStateError)
  }

  return result
}

// -------------------------------------------------------------------

export async function initiateLogin (): Promise<PzApiResData> {
  return await pzApiSrvLoginPrepare()
}

/// -------------------------------------------------------------------

export async function loginWithEmailAndPassword ({
  email,
  password,
  flowId,
  csrfToken
}: {
  email: string
  password: string
  flowId: string
  csrfToken: string
}): Promise<{ ok?: boolean, errorMsg?: string }> {
  authState = AUTH_STATE_LOGGING_IN
  log(4, 'authState', authState)

  const data = await pzApiSrvLogin({
    identifier: email,
    password,
    flowId,
    csrfToken
  })

  if (data.errMsg !== undefined) {
    authStateError = data.errMsg
    log(0, 'loginWithEmailAndPassword authState', authState, authStateError)
  }

  return await extractLoginOrRegistrationDataFromResponse(data)
}

// -------------------------------------------------------------------

async function extractLoginOrRegistrationDataFromResponse (data: {
  ok: any
  errMsg?: string | undefined
  identityId?: any
  details?: any // Add details field
}): Promise<{ ok?: boolean, errorMsg?: string }> {
  if (data?.ok === true && data?.identityId !== undefined && data?.identityId !== '') {
    await checkAuthState()
    return { ok: true }
  } else {
    authState = AUTH_STATE_ERROR
    const errorMessage = data?.errMsg ??
      (data?.details ? JSON.stringify(data.details) : 'Authentication failed. Please try again.')
    log(0, 'Auth err', errorMessage)

    return {
      errorMsg: errorMessage
    }
  }
}

// -------------------------------------------------------------------

export async function logout (): Promise<void> {
  authState = AUTH_STATE_LOGGING_OUT
  log(4, 'authState', authState)

  await pzApiSrvLogout()

  await checkAuthState()

  // Force a page reload to ensure a clean state
  window.location.reload()
}

// -------------------------------------------------------------------

function checkAuthenticationState (): { isAuthenticated: boolean | null } {
  // TODO This is not a good check, as it does not take all states into account.
  // TODO This should be converted to a computed state instead.
  if (authState === AUTH_STATE_SIGNED_IN || authState === AUTH_STATE_ANONYMOUS) {
    return { isAuthenticated: true }
  }
  if (authState !== AUTH_STATE_INITIAL &&
      authState !== AUTH_STATE_CHECKING_AUTH_STATE &&
      authState !== AUTH_STATE_REGISTERING) {
    return { isAuthenticated: false }
  }
  return { isAuthenticated: null }
}

// -------------------------------------------------------------------

export async function isAuthenticatedAsync (): Promise<boolean> {
  const initialCheck = checkAuthenticationState()
  if (initialCheck.isAuthenticated !== null) {
    return initialCheck.isAuthenticated
  }

  // Otherwise wait for state to settle
  return new Promise((resolve) => {
    const timeoutTaskId = 'authStateTimeout'

    // Failsafe: resolve after a timeout to prevent hanging tests
    timerService.addTask(timeoutTaskId, () => {
      timerService.removeTask(timeoutTaskId)
      resolve(false)
    }, 15_000)

    $effect(() => {
      const check = checkAuthenticationState()
      if (check.isAuthenticated !== null) {
        timerService.removeTask(timeoutTaskId)
        resolve(check.isAuthenticated)
      }
    })
  })
}

// -------------------------------------------------------------------

export function subscribeAuthenticatedUser (): void {
  if (!userSubscriptionStarted) {
    userSubscriptionStarted = true

    // TODO Somehow subscribe/poll to authenticated user changes on the server side.
  }
}

// -------------------------------------------------------------------

export async function updateAuthenticatedUserData (data: any): Promise<void> {
  await pzApiSrvUpdateUserData({
    displayName: data.displayName,
    email: data.email
  })

  void checkAuthState()
}
