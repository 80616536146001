import { pzApiAuthDef } from './auth/authApiDefs'
import { pzApiSpacesDef } from './spaces/spacesApiDefs'
import { pzApiTribesDef } from './tribes/tribesApiDefs'
import { pzApiUsersDef } from './users/usersApiDefs'

// -------------------------------------------------------------------

export interface PzApiRouteBase {
  fetchUrl: string
  fetchMethod: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  includeContext?: boolean
  skipAuthCheck?: boolean
}

// -------------------------------------------------------------------

export interface PzApiRoute<ReqData, ResData> extends PzApiRouteBase {
  reqData?: ReqData // Optional because some endpoints don't require a body
  resData: ResData
}

// -------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/ban-types
export type PzApiEmptyReqData = Record<string, never> & {} // Force it to be an empty object.

// -------------------------------------------------------------------

export interface PzApiResDataSuccess {
  ok: true
}

export interface PzApiResDataFail {
  ok: false
  errMsg: string
}

export type PzApiResData = PzApiResDataSuccess | PzApiResDataFail

// -------------------------------------------------------------------

export const pzApi = {
  auth: pzApiAuthDef,
  spaces: pzApiSpacesDef,
  tribes: pzApiTribesDef,
  users: pzApiUsersDef
} as const
