// -------------------------------------------------------------------
// Functionality for:
// - Scheduling tasks to run at regular intervals
// - Throttling tasks to run at most once within a specified time frame (throttle)
// - Debouncing tasks to run after a specified quiet period (debounce)
// - setTimeout loops
// - setInterval loops
//
// Tasks run as soon as possible after their interval has elapsed
// Each task drifts based on its own execution time (lastRun is set after completion)
// Tasks don't block each other (thanks to Promise.resolve())
// The timer service wakes up at the earliest needed time (via getNextTickDelay())
// -------------------------------------------------------------------

type TimerCallback = () => void
type ThrottledCallback = (..._args: any[]) => Promise<void>

// -------------------------------------------------------------------

interface TimerTask {
  callback: TimerCallback
  interval: number
  lastRun: number
}

// -------------------------------------------------------------------

interface ThrottledTask {
  callback: ThrottledCallback
  delay: number
  lastRun: number
  pending: boolean
  args: any[]
}

// -------------------------------------------------------------------

class TimerService {
  private tasks: Map<string, TimerTask> = new Map()
  private throttledTasks: Map<string, ThrottledTask> = new Map()
  private timeoutId: ReturnType<typeof setTimeout> | null = null

  private getNextTickDelay (): number {
    if (this.tasks.size === 0) return 1000 // Default when no tasks

    const now = Date.now()
    let nextRun = Infinity

    // Find the next task that needs to run
    for (const task of this.tasks.values()) {
      const taskNextRun = task.lastRun + task.interval
      nextRun = Math.min(nextRun, taskNextRun)
    }

    // Calculate delay until next run (minimum 0)
    return Math.max(0, nextRun - now)
  }

  // -------------------------------------------------------------------

  start (): void {
    if (this.timeoutId) return

    const tick = (): void => {
      const now = Date.now()

      for (const [, task] of this.tasks) {
        if (now >= task.lastRun + task.interval) {
          // Run each task in its own Promise to prevent blocking
          void Promise.resolve().then(() => {
            task.callback()
            task.lastRun = Date.now() // Use actual completion time for drift
          })
        }
      }

      this.timeoutId = setTimeout(tick, this.getNextTickDelay())
    }

    tick()
  }

  // -------------------------------------------------------------------

  stop (): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  // -------------------------------------------------------------------

  addTask (id: string, callback: TimerCallback, interval: number): void {
    this.tasks.set(id, {
      callback,
      interval,
      lastRun: Date.now()
    })
  }

  // -------------------------------------------------------------------

  removeTask (id: string): void {
    this.tasks.delete(id)
  }

  // -------------------------------------------------------------------

  private executeThrottledTask (task: ThrottledTask, now: number): void {
    task.pending = true
    task.lastRun = now
    void task.callback(...task.args).finally(() => {
      task.pending = false
    })
  }

  // -------------------------------------------------------------------

  addThrottledTask (id: string, callback: ThrottledCallback, delay: number): (..._args: any[]) => void {
    if (!this.throttledTasks.has(id)) {
      this.throttledTasks.set(id, {
        callback,
        delay,
        lastRun: 0,
        pending: false,
        args: []
      })
    }

    return (...args: any[]): void => {
      const task = this.throttledTasks.get(id)!
      const now = Date.now()
      task.args = args

      if (!task.pending) {
        const timeSinceLastRun = now - task.lastRun
        if (timeSinceLastRun >= task.delay) {
          this.executeThrottledTask(task, now)
        } else {
          task.pending = true
          setTimeout(() => {
            this.executeThrottledTask(task, Date.now())
          }, task.delay - timeSinceLastRun)
        }
      }
    }
  }

  // -------------------------------------------------------------------

  removeThrottledTask (id: string): void {
    this.throttledTasks.delete(id)
  }
}

// -------------------------------------------------------------------

export const timerService = new TimerService()
