/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidV7 } from '@utils/id'

import type { PzApiEmptyReqData, PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { pzApiAuthSlug } from './authBaseApiDefs'

// -------------------------------------------------------------------

interface PzApiAuthWhoAmIResDataSuccess extends PzApiResDataSuccess {
  authenticated: true
  id: UuidV7
  email: string
  name: string
}

// -------------------------------------------------------------------

interface PzApiAuthWhoAmIResDataNotAuthenticated extends PzApiResDataSuccess {
  authenticated: false
}

// -------------------------------------------------------------------

export type PzApiAuthWhoAmIResData =
  | PzApiAuthWhoAmIResDataSuccess
  | PzApiAuthWhoAmIResDataNotAuthenticated
  | PzApiResDataFail

// -------------------------------------------------------------------

export const pzApiAuthWhoAmIDef = {
  fetchUrl: `/${pzApiAuthSlug}/whoami`,
  fetchMethod: 'GET',
  includeContext: true,
  skipAuthCheck: true,
  reqData: {} as PzApiEmptyReqData,
  resData: {} as PzApiAuthWhoAmIResData
} satisfies PzApiRoute<PzApiEmptyReqData, PzApiAuthWhoAmIResData>
