// -------------------------------------------------------------------

import { newMiniId } from '@/utils/id.ts'

export type SideMenuOpenState = '' | 'opening' | 'closing' | 'open' | 'closed' | 'hidden'

// -------------------------------------------------------------------

interface SideMenuStackItem {
  onClose: () => void
  initClose: () => void
  // openState: Ref<SideMenuOpenState> | SideMenuOpenState
  openState: SideMenuOpenState
  menuId: string
}

interface SideMenuStartOptions {
  onClose: () => void
}

// -------------------------------------------------------------------

export const APP_SIDE_MENU_OPEN_DURATION = 0.3

document.documentElement.style.setProperty('--z-app-side-menu-open-duration', APP_SIDE_MENU_OPEN_DURATION + 's')

// export const sideMenuStack: Ref<SideMenuStackItem[]> = ref([])
export const sideMenuStack: SideMenuStackItem[] = $state([])

let sideMenuOverlayClosing = $state(false)

export const xSideMenuOverlayClosing = (): boolean => {
  return sideMenuOverlayClosing
}

// -------------------------------------------------------------------

export const xSideMenuHasContent = (): boolean => {
  return sideMenuStack.length > 0
}

// -------------------------------------------------------------------

export function startNewSideMenu (options: SideMenuStartOptions): SideMenuStackItem {
  // const openState: Ref<SideMenuOpenState> = ref('')
  let openState: SideMenuOpenState = $state('')

  if (sideMenuStack.length > 0) {
    sideMenuStack[sideMenuStack.length - 1].openState = 'closing' // This will trigger the closing animation of the previous side menu.
  }

  const sideMenuStackItem: SideMenuStackItem = {
    onClose: options.onClose,
    get openState () {
      return openState
    },
    set openState (state) {
      openState = state
    },
    initClose: () => {
      sideMenuCloseOne(sideMenuStackItem, false)
    },
    menuId: newMiniId()
  }

  sideMenuStack.push(sideMenuStackItem)

  setTimeout(() => {
    setOpenState(sideMenuStack[sideMenuStack.length - 1], 'opening')
  }, 1) // Using setTimeout, so fist the dom has a chance to render the component with it being 'open', so the css transition animation will be started.

  return sideMenuStackItem
}

// -------------------------------------------------------------------

function setOpenState (sideMenuStackItem: SideMenuStackItem, state: SideMenuOpenState): void {
  sideMenuStackItem.openState = state

  let stateAfterAnimation: SideMenuOpenState = ''
  if (state === 'opening') {
    stateAfterAnimation = 'open'
  }
  if (state === 'closing') {
    stateAfterAnimation = 'closed'
  }

  if (stateAfterAnimation !== '') {
    setTimeout(() => {
      setOpenState(sideMenuStackItem, stateAfterAnimation)
    }, APP_SIDE_MENU_OPEN_DURATION * 1000)
  }
}

// -------------------------------------------------------------------

export function sideMenuCloseOne (sideMenuStackItem: SideMenuStackItem, isClosingAll: boolean = false): void {
  if (sideMenuStack.length === 1 || isClosingAll) {
    sideMenuOverlayClosing = true
  }

  // Trigger the closing animation of a side menu.
  setOpenState(sideMenuStackItem, 'closing')

  const index = sideMenuStack.findIndex((n) => n.menuId === sideMenuStackItem.menuId)
  if (index > 0 && !isClosingAll) {
    setOpenState(sideMenuStack[index - 1], 'opening')
  }

  // Then, after the closing animation is done, trigger the callback of the side menu, so it can unmount itself.
  setTimeout(() => {
    const index2 = sideMenuStack.findIndex((n) => n.menuId === sideMenuStackItem.menuId)
    if (index2 > -1 || isClosingAll) {
      sideMenuStack.splice(index2, 1)
    }
    sideMenuStackItem.onClose()

    sideMenuOverlayClosing = false
  }, APP_SIDE_MENU_OPEN_DURATION * 1000)
}

// -------------------------------------------------------------------

export function sideMenuCloseAll (): void {
  for (const ix of sideMenuStack.keys()) {
    sideMenuCloseOne(sideMenuStack[ix], true)
  }
}
