import { t } from 'i18next'

import { pzApiSrvCreateTribeForUser, pzApiSrvGetTribes } from '@/services/pzApiSrv.ts'
import { log } from '@/utils/debug.ts'
import { newUuidB62, type UuidB62 } from '@/utils/id.ts'

import { appState, injectState, type UseStateReturn } from './appState.svelte.ts'

import { processTribeUsers } from './user.svelte.ts'

// -------------------------------------------------------------------

interface TribeUser {
  id: UuidB62
  rights: string
  inviteeEmail?: string
}

// -------------------------------------------------------------------


export type TribeUsers = Record<UuidB62, TribeUser>

// -------------------------------------------------------------------

export interface Tribe {
  id: UuidB62
  name: string
  displayName?: string
  users: TribeUsers
}

// -------------------------------------------------------------------

export type Tribes = Record<UuidB62, Tribe>

let tribes: Tribes = $state({})
let loading = $state(false)
let error = $state<string | null>(null)
let isTribesKnown = $state(false)
let activeTribe: Tribe | undefined = $state()

// -------------------------------------------------------------------

export interface TribeState {
  tribes: Tribes
  loading: boolean
  error: string | null
  isTribesKnown: boolean
  activeTribe: Tribe | undefined
}

// -------------------------------------------------------------------

export function createNewBasicTribe (userId: UuidB62, name = '_NEW_'): { id: UuidB62, name: string } {
  return { id: newUuidB62(), name }
}

// -------------------------------------------------------------------

function handleAnonymousUserTribes (userId: UuidB62): void {
  const localStorageKey = `anonymous_tribes_${userId}`
  const storedTribes = localStorage.getItem(localStorageKey)

  if (!storedTribes) {
    createLocalTribeForAnonymousUser(userId)
    return
  }

  try {
    tribes = JSON.parse(storedTribes)
  } catch (e) {
    log(0, 'Error parsing stored tribes, creating new one', e)
    createLocalTribeForAnonymousUser(userId)
  }
}

// -------------------------------------------------------------------

function computeTribeDisplayName (tribe: Tribe): string {
  if (tribe.name !== '_NEW_') {
    return tribe.name
  }

  // Find the owner in the users
  const owner = Object.values(tribe.users).find(user => user.rights === 'o')
  if (!owner) {
    return t('New Team')
  }

  return t('Team of') + ' ' + (appState.users?.[owner.id]?.displayName ?? owner.id)
}

// -------------------------------------------------------------------

function updateTribesFromServerData (tribesFromServer: Tribe[]): void {
  if (tribesFromServer.length > 0) {
    tribes = Object.fromEntries(
      tribesFromServer.map(tribe => [
        tribe.id,
        {
          ...tribe,
          displayName: computeTribeDisplayName(tribe)
        }
      ])
    )

    // Collect all unique users from all tribes
    const allTribeUsers: Record<UuidB62, { id: UuidB62, inviteeEmail?: string }> = {}
    tribesFromServer.forEach(tribe => {
      Object.values(tribe.users).forEach(user => {
        allTribeUsers[user.id] = user
      })
    })

    // Process all users at once
    processTribeUsers(allTribeUsers)
  }
}

// -------------------------------------------------------------------

async function getAndUpdateTribesFromServer (userId: UuidB62): Promise<Tribe[]> {
  const tribesFromServer = await pzApiSrvGetTribes(userId)
  updateTribesFromServerData(tribesFromServer)
  return tribesFromServer
}

// -------------------------------------------------------------------

export async function syncTribesFromServer (userId: UuidB62): Promise<void> {
  await getAndUpdateTribesFromServer(userId)
}

// -------------------------------------------------------------------

async function handleRegisteredUserTribes (userId: UuidB62): Promise<void> {
  const tribesFromServer = await getAndUpdateTribesFromServer(userId)

  if (tribesFromServer.length > 0) {
    return
  }

  const { id, name } = createNewBasicTribe(userId, '_NEW_')
  const newTribesData = await pzApiSrvCreateTribeForUser(id, name)

  if (newTribesData.length > 0) {
    updateTribesFromServerData(newTribesData)
  } else {
    log(1, 'Error: no tribes after getting from server or creating one')
    createNewTribeWithUsers(userId)
  }
}

// -------------------------------------------------------------------

async function retrieveOrCreateTribes (userId: UuidB62): Promise<void> {
  loading = true
  error = null

  try {
    await (appState.isAnonymousUser
      ? handleAnonymousUserTribes(userId)
      : handleRegisteredUserTribes(userId))

    isTribesKnown = true
    setActiveTribeIfNotCorrectlySet()
  } catch (err) {
    log(0, 'Error retrieving or creating tribes:', err)
    // Optionally create a local tribe as fallback
  } finally {
    loading = false
  }
}

// -------------------------------------------------------------------

function createNewTribeWithUsers (userId: UuidB62, name?: string): Tribe {
  const tribe = {
    ...createNewBasicTribe(userId, name),
    users: {
      [userId]: {
        id: userId,
        rights: 'owner'
      }
    }
  }

  tribes = {
    [tribe.id]: tribe
  }

  return tribe
}

// -------------------------------------------------------------------

function createLocalTribeForAnonymousUser (userId: UuidB62): void {
  createNewTribeWithUsers(userId)
  const localStorageKey = `anonymous_tribes_${userId}`
  localStorage.setItem(localStorageKey, JSON.stringify(tribes))
}

// -------------------------------------------------------------------

function getActiveTribeStorageKey (instanceId: string): string {
  return `active_tribe_${instanceId}`
}

// -------------------------------------------------------------------

function saveActiveTribeToStorage (tribeId: UuidB62): void {
  if (appState.instanceId) {
    const storageKey = getActiveTribeStorageKey(appState.instanceId)
    localStorage.setItem(storageKey, tribeId)
  }
}

// -------------------------------------------------------------------

function loadActiveTribeFromStorage (): UuidB62 | undefined {
  if (appState.instanceId) {
    const storageKey = getActiveTribeStorageKey(appState.instanceId)
    return localStorage.getItem(storageKey) as UuidB62 || undefined
  }
  return undefined
}

// -------------------------------------------------------------------

function setActiveTribeIfNotCorrectlySet (): void {
  const storedTribeId = loadActiveTribeFromStorage()

  if (
    (activeTribe === undefined && Object.values(tribes).length > 0) ||
    (activeTribe !== undefined && tribes[activeTribe.id] === undefined)
  ) {
    // Try to set the stored tribe if it exists and is valid
    if (storedTribeId && tribes[storedTribeId]) {
      activeTribe = tribes[storedTribeId]
    } else {
      // Fall back to the first tribe if stored tribe is invalid
      activeTribe = Object.values(tribes)[0]
      if (activeTribe) {
        saveActiveTribeToStorage(activeTribe.id)
      }
    }
  }
}

// -------------------------------------------------------------------

export function setActiveTribe (tribeId: UuidB62): void {
  activeTribe = tribes[tribeId]
  if (activeTribe) {
    saveActiveTribeToStorage(tribeId)
  }
}

// -------------------------------------------------------------------

export function useTribes (): UseStateReturn {
  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      $effect.root(() => {
        $effect(() => {
          if (appState.isLoggedInAndIdKnown && appState.authenticatedUserId) {
            void retrieveOrCreateTribes(appState.authenticatedUserId)
          }
        })
      })
    },
    extendState (): ReturnType<UseStateReturn['extendState']> {
      injectState('tribes', () => tribes)
      injectState('tribesLoading', () => loading)
      injectState('tribesError', () => error)
      injectState('isTribesKnown', () => isTribesKnown)
      injectState('activeTribe', () => activeTribe)
    }
  }
}
