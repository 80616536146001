<script lang="ts">
  import type { Component, Snippet } from 'svelte'

  import { cn } from '../utils/shadcnUtils.ts'

  // -------------------------------------------------------------------

  const {
    onclick,
    icon,
    children,
    ...props
  }: { onclick?: () => void, icon: Component, children?: Snippet, class?: string } = $props()

  // const className: string = typeof props.class === 'string' ? props.class : ''
  const className = $derived.by(() => {
    return typeof props.class === 'string' ? props.class : ''
  })
</script>

<!-- ------------------------------------------------------------- -->

<div
  class={cn('z-icon size-8 text-foreground hover:text-primary', className)}
  {onclick}
  onkeydown={(e) => {
    e.preventDefault()
    e.key === 'Enter' && onclick?.()
  }}
  role="button"
  tabindex="0"
>
  {#if children}
    {@render children()}
  {/if}

  {#if icon}
    {@const Component = icon}
    <Component />
  {/if}
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.z-icon
  display flex
  place-items center
  place-content center
  cursor pointer
  :global(svg)
    width 100% // Otherwise not showing on iPhone.
    height 100%
</style>
