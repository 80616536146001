import Dexie from 'dexie'
import * as Y from 'yjs'

import { syncMorphToServer } from '@/services/morphSync.ts'
import { type Instance } from '@/store/instance.svelte.ts'
import {
  checkInstanceClocksExist,
  morphCheckAndChangeClock,
  updateAllInstanceClocksReceived,
  updateAllInstanceClocksSeen } from '@/store/instanceClock.ts'
import { type UuidB62 } from '@/utils/id.ts'

// -------------------------------------------------------------------

export interface DbMorph {
  id: string
  instanceId: string
  spaceId: string
  folderId?: string
  itemId: string
  breed: string
  ky: string
  instanceClock: number
  ymut: Uint8Array
}

// -------------------------------------------------------------------

export class PzDexie extends Dexie {
  // 'morphs' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  morphs!: Dexie.Table<DbMorph>

  constructor (dbName: string) {
    super(dbName)

    this.version(1).stores({
      morphs: '++id, instanceId, spaceId, folderId, itemId, breed' // Primary key and indexed props // TODO Add ky and instanceClock as indexed?
    })
  }
}

// -------------------------------------------------------------------

export interface DB {
  name: string
  db: PzDexie
  instance: Instance
}

// -------------------------------------------------------------------

export function dexieInitDb (dbName: string, instance: Instance): DB {
  const db: DB = {
    name: dbName,
    db: new PzDexie(dbName),
    instance
  }
  return db
}

// -------------------------------------------------------------------

export async function dexieGetAllMorphs (db: DB): Promise<DbMorph[]> {
  // console.log('dexieGetAllMorphs 000', db)
  const ret = await db.db.morphs.toArray()
  // console.log('dexieGetAllMorphs 111', ret)
  return ret
}

// -------------------------------------------------------------------

export async function dexieGetAllMorphsByBreed (
  db: DB,
  breed: string,
  callbackSubscription?: (result: DbMorph[]) => void
): Promise<DbMorph[]> {
  return await dexieGetMorphsByKey({ db, ky: 'breed', val: breed, callbackSubscription })
}

// -------------------------------------------------------------------

export async function dexieGetMorphsByItemId (
  db: DB,
  id: UuidB62,
  callbackSubscription?: (result: DbMorph[]) => void
): Promise<DbMorph[]> {
  return await dexieGetMorphsByKey({ db, ky: 'itemId', val: id, callbackSubscription })
}

// -------------------------------------------------------------------

export async function dexieGetMorphsById (
  db: DB,
  id: UuidB62,
  callbackSubscription?: (result: DbMorph[]) => void
): Promise<DbMorph[]> {
  return await dexieGetMorphsByKey({ db, ky: 'id', val: id, callbackSubscription })
}

// -------------------------------------------------------------------

export async function dexieGetMorphsByKey ({
  db,
  val,
  ky,
  callbackSubscription
}: {
  db: DB
  val: UuidB62 | string
  ky: string
  callbackSubscription?: (result: DbMorph[]) => void
}): Promise<DbMorph[]> {
  if (callbackSubscription === undefined) {
    // console.log('dexieGetMorphsByKey 000', db)
    const ret = val !== undefined ? await db.db.morphs.where(ky).equals(val)
      .toArray() : []
    // console.log('dexieGetMorphsByKey 111', ret)

    return ret
  } else {
    return await new Promise((resolve, reject) => {
      const itemObservable = Dexie.liveQuery(async () => await db.db.morphs.where(ky).equals(val)
        .toArray())

      /* const subscription = */ itemObservable.subscribe({
        next: (result: unknown) => {
          // Result is an array of morphs that match the query, even if some of them where already returned in a previous result.
          // console.log('dexieGetMorphsByKey liveQuery:', JSON.stringify(result), result)
          resolve(result as DbMorph[])
          callbackSubscription(result as DbMorph[])
          // TODO This will re-apply all morphs, even if they have already been applied. Because the Dexie liveQuery will re-trigger the callback for all morphs.
        },
        error: (err) => {
          console.error(err)
          reject(err)
        }
      })
      // TODO Unsubscribe (and add a test for it)
    })
  }
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
export async function dexieAddMorph (db: DB, morph: DbMorph): Promise<UuidB62> {
  // console.log('dexieAddMorph 000', db, morph)

  const morphs = await dexieGetAllMorphs(db)
  // TODO Only get morphs for the current item?
  // TODO Cache?

  updateAllInstanceClocksSeen(db.instance, morphs)

  // dexieCheckIfMorphsCanBeMerged(morphs, morph)

  // let mergedMorph: Uint8Array | null = null
  // for (const m of morphs) {
  //   if (mergedMorph) {
  //     mergedMorph = Y.mergeUpdates([mergedMorph, m.ymut])
  //   } else {
  //     mergedMorph = m.ymut
  //   }

  //   log('m', m.ymut.length)
  // }
  // log('mergedMorph', mergedMorph?.length)


  morphCheckAndChangeClock(db.instance, morph)

  const ret: UuidB62 = await db.db.morphs.add(morph)
  // console.log('dexieAddMorph 111', ret)

  // morphs.push(morph)

  // TODO Is this the right (and only) place to call this?
  // TODO Send ALL morphs that the server does not yet have. (Ask the server first?)
  // TODO Throttle
  // TODO Merge all updates that have not yet been sent to the server (or anyone), so that there is only one merges Yjs update to send.
  // void dexieSendSrvMorphs(db, morph.spaceId as UuidB62, [morph])

  void syncMorphToServer(db, morph)

  return ret
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
function dexieCheckIfMorphsCanBeMerged (morphs: DbMorph[], morph: DbMorph): void {
  let highestClock = -1
  let highestMorph = null

  for (const m of morphs) {
    if (m.itemId === morph.itemId && m.breed === morph.breed && m.ky === morph.ky) {
      // The itemId and ky are the same, so we can merge the morphs.
      // console.log('dexieCheckIfMorphsCanBeMerged 111', m, morph)
      if (highestClock < m.instanceClock) {
        highestClock = m.instanceClock
        highestMorph = m
      }
    }
  }

  if (highestMorph !== null) {
    Y.logUpdate(highestMorph.ymut)
    // console.log('dexieCheckIfMorphsCanBeMerged 222', highestMorph, morph)
    // Y.logUpdate(morph.ymut)
    const newYMut = Y.mergeUpdates([highestMorph.ymut, morph.ymut])
    // console.log('dexieCheckIfMorphsCanBeMerged 333', newYMut)
    // Y.logUpdate(newYMut)
  }
}

// -------------------------------------------------------------------

export async function dexieDestroyDb (dbName: string): Promise<void> {
  await Dexie.delete(dbName)
}

// -------------------------------------------------------------------

export async function dexieProcessSrvMorphs (db: DB, spaceId: string, dbMorphs: DbMorph[]): Promise<void> {
  // console.log('dexieProcessSrvMorphs')
  // log('dexieProcessSrvMorphs ' + dbMorphs?.length)

  for (const dbMorph of dbMorphs) {
    // console.log('dexieProcessSrvMorphs 222', dbMorph)
    const existingMorphs = await dexieGetMorphsById(db, dbMorph.id as UuidB62)
    // console.log('dexieProcessSrvMorphs 444', existingMorphs)
    // console.log('dexieProcessSrvMorphs RECEIVED', existingMorphs, dbMorph)
    if (existingMorphs.length < 1) {
      // console.log('dexieProcessSrvMorphs RECEIVED NEW', dbMorph)
      await dexieAddMorph(db, dbMorph)
    }

    checkInstanceClocksExist(db.instance, dbMorph.instanceId)
    updateAllInstanceClocksReceived(db.instance, [dbMorph])
  }
}

// -------------------------------------------------------------------

export async function dexieClearDb (db: DB): Promise<void> {
  await db.db.morphs.clear()
}
