import { pzApiSrvGetUsersDetails } from '@/services/pzApiSrv.ts'
import { appState } from '@/store/appState.svelte.ts'
import { log } from '@/utils/debug.ts'
import { type UuidB62 } from '@/utils/id.ts'

import { injectState, type UseStateReturn } from './appState.svelte.ts'

// -------------------------------------------------------------------

export interface User {
  id: UuidB62
  email?: string
  displayName?: string
  loading?: boolean
}

// -------------------------------------------------------------------

export type Users = Record<UuidB62, User>

let users: Users = $state({})
let loading = $state(false)
let error = $state<string | null>(null)
let pendingFetch = new Set<UuidB62>()
let userDetailsRequestScheduled = false

// -------------------------------------------------------------------

export interface UserState {
  users: Users
  loading: boolean
  error: string | null
}

// -------------------------------------------------------------------

export function useUsers (): UseStateReturn {
  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      // No initial setup needed
    },
    extendState (): ReturnType<UseStateReturn['extendState']> {
      injectState('users', () => users)
      injectState('usersLoading', () => loading)
      injectState('usersError', () => error)
    }
  }
}

// -------------------------------------------------------------------

function addUserToStore (userId: UuidB62, partialUser: Partial<User> = {}): void {
  // log('addUserToStore', userId, partialUser)

  if (!users[userId]) {
    users[userId] = {
      id: userId,
      ...partialUser
    }
    pendingFetch.add(userId)
  }
}

// -------------------------------------------------------------------

interface UserDetails {
  id: UuidB62
  email?: string
  displayName?: string
}

// -------------------------------------------------------------------

function updateUserDetails (usersStore: Users, details: UserDetails[]): void {
  // log('updateUserDetails - input:', details)
  details.forEach(detail => {
    usersStore[detail.id] = { ...usersStore[detail.id], ...detail, loading: false }
    // log('updateUserDetails - updated user:', detail.id, usersStore[detail.id])
  })
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
async function fetchPendingUserDetails (): Promise<void> {
  if (pendingFetch.size === 0 || loading || userDetailsRequestScheduled) {
    return
  }

  userDetailsRequestScheduled = true

  // Wait for any additional users to be added to pendingFetch
  await Promise.resolve()

  const userIdsToFetch = Array.from(pendingFetch)
  pendingFetch.clear()
  loading = true
  userDetailsRequestScheduled = false

  try {
    const details = await pzApiSrvGetUsersDetails(userIdsToFetch)
    updateUserDetails(users, details)
  } catch (err) {
    log(0, 'Error fetching user details:', err)
    updateUserDetails(users, userIdsToFetch.map(id => ({ id })))
    error = err instanceof Error ? err.message : 'Failed to fetch user details'
  } finally {
    loading = false

    // If there are still pending users, trigger another fetch
    if (pendingFetch.size > 0) {
      void fetchPendingUserDetails()
    }
  }
}

// -------------------------------------------------------------------

export function processSpaceUsers (spaceUsers: Record<UuidB62, { id: UuidB62 }>): void {
  Object.values(spaceUsers).forEach(user => {
    addUserToStore(user.id)
  })
  void fetchPendingUserDetails()
}

// -------------------------------------------------------------------

export function processTribeUsers (
  tribeUsers: Record<UuidB62, { id: UuidB62, inviteeEmail?: string }>
): void {
  const newUsers = Object.values(tribeUsers)

  // Process all users first
  newUsers.forEach(user => {
    // Always update if user doesn't exist or is in loading state
    if (!(user.id in users) || users[user.id].loading) {
      users[user.id] = {
        loading: true,
        // Preserve any existing data we might have
        ...users[user.id],
        // Override with new data if available
        ...(user.inviteeEmail ? { email: user.inviteeEmail } : {})
      }
      pendingFetch.add(user.id)
    }
  })

  void fetchPendingUserDetails()
}

// -------------------------------------------------------------------

export async function syncUsersFromServer (): Promise<void> {
  if (!appState.authenticatedUserId) return

  // Get all user IDs we're currently tracking
  const userIdsToSync = Object.keys(users) as UuidB62[]
  if (userIdsToSync.length === 0) return

  try {
    const details = await pzApiSrvGetUsersDetails(userIdsToSync)
    updateUserDetails(users, details)
  } catch (err) {
    log(0, 'Error syncing user details:', err)
    error = err instanceof Error ? err.message : 'Failed to sync user details'
  }
}
