import { updateSyncState } from '../store/syncState.svelte.ts'

import { timerService } from './timerService.ts'

// -------------------------------------------------------------------

let isConnected = false

// -------------------------------------------------------------------

function checkNetworkConnection (): boolean {
  if (!navigator.onLine) {
    console.log('[ConnectionManager] Browser reports offline')
    return false
  }

  // TODO Use serverManager's health status

  return true
}

// -------------------------------------------------------------------

const checkNetworkState = (): Promise<void> => {
  const wasConnected = isConnected
  isConnected = checkNetworkConnection()

  if (wasConnected !== isConnected) {
    updateSyncState({
      isConnected,
      isOffline: !isConnected,
      isSyncing: false,
      hasError: false
    })
  }

  return Promise.resolve()
}

// -------------------------------------------------------------------

const handleNetworkChange = timerService.addThrottledTask(
  'networkStateChange',
  checkNetworkState,
  1000
)

// -------------------------------------------------------------------

export function initializeNetworkMonitoring (): void {
  // Initial check
  handleNetworkChange()

  // Browser online/offline events
  window.addEventListener('online', () => {
    console.log('[ConnectionManager] Browser online event')
    handleNetworkChange()
  })

  window.addEventListener('offline', () => {
    console.log('[ConnectionManager] Browser offline event')
    isConnected = false
    updateSyncState({
      isConnected: false,
      isOffline: true,
      isSyncing: false
    })
  })

  // Regular connection check
  timerService.addTask('connectionCheck', handleNetworkChange, 5000)
}

// -------------------------------------------------------------------

export function isNetworkError (error: unknown): boolean {
  if (error instanceof Error) {
    return (
      error.name === 'NetworkError' ||
      error.message.includes('network') ||
      error.message.includes('Failed to fetch') ||
      error.message.includes('Network request failed')
    )
  }
  return false
}
