import { pzApiTribesAddUserDef } from './tribesAddUserApiDefs'
import { pzApiTribesDeleteDef } from './tribesDeleteApiDefs'
import { pzApiTribesInsertDef } from './tribesInsertApiDefs'
import { pzApiTribesListDef } from './tribesListApiDefs'
import { pzApiTribesRespondToInviteDef } from './tribesRespondToInviteApiDefs'
import { pzApiTribesUpdateDef } from './tribesUpdateApiDefs'
import { pzApiTribesUpdateUserDef } from './tribesUpdateUserApiDefs'

export const pzApiTribesDef = {
  list: pzApiTribesListDef,
  insert: pzApiTribesInsertDef,
  update: pzApiTribesUpdateDef,
  delete: pzApiTribesDeleteDef,
  addUser: pzApiTribesAddUserDef,
  updateUser: pzApiTribesUpdateUserDef,
  respondToInvite: pzApiTribesRespondToInviteDef
} as const
