import { personModuleInit } from '@/modules/contactModule.ts'
import { prayerModuleInit } from '@/modules/prayerModule.ts'

import { type AuthState, useAuth } from './auth.svelte.ts'
import { getAppVersion } from './env.ts'
import { type InstanceState, useInstance } from './instance.svelte.ts'
import { type MorphSrvClientsState, useMorphSrvClients } from './morphSrvClients.svelte.ts'
import { usePwa } from './pwa.svelte.ts'
import { type ReadyForActionState, useReadyState } from './readyForAction.svelte.ts'
import { initializeServices } from './services.svelte.ts'
import { type SpaceState, useSpaces } from './space.svelte.ts'
import { type TribeState, useTribes } from './tribe.svelte.ts'
// import { useInstanceDb, type InstanceState } from './instanceDb'
import { type UserState, useUsers } from './user.svelte.ts'
import { type UserDbState, useUserDb } from './userDb.svelte.ts'

// -------------------------------------------------------------------

export interface UseStateReturn {
  start: () => void
  extendState: (_aState: AppState) => void
}

// -------------------------------------------------------------------

const auth = useAuth()
const readystate = useReadyState()
const userDb = useUserDb()
const instance = useInstance()
// const instanceDb = useInstanceDb()
// const spaceDbs = useSpaceDbs()
const spaces = useSpaces()
const tribes = useTribes()
const users = useUsers()
const morphSrvClient = useMorphSrvClients()
usePwa()

// -------------------------------------------------------------------

// Partial<...> makes the properties optional.
// interface AppState extends Partial<InstanceState> {
export interface AppState
  extends Partial<AuthState>,
  Partial<MorphSrvClientsState>,
  Partial<UserDbState>,
  Partial<InstanceState>,
  Partial<SpaceState>,
  Partial<TribeState>,
  Partial<UserState>,
  Partial<ReadyForActionState> {
    appVersion: string
  }

// -------------------------------------------------------------------

export const appState: AppState = {
  appVersion: getAppVersion()
}

// -------------------------------------------------------------------

// Extend state with various modules
auth.extendState(appState)
readystate.extendState(appState)
userDb.extendState(appState)
instance.extendState(appState)
spaces.extendState(appState)
tribes.extendState(appState)
users.extendState(appState)
morphSrvClient.extendState(appState)

// -------------------------------------------------------------------

// Initialize feature modules
personModuleInit()
prayerModuleInit()

// -------------------------------------------------------------------

// Start core services
initializeServices()

// Start application services
instance.start()
userDb.start()
spaces.start()
tribes.start()
morphSrvClient.start()
auth.start() // Must be last!?

// -------------------------------------------------------------------

export function injectState (ky: string, get: any, set: any = null): void {
  Object.defineProperty(appState, ky, {
    get,
    ...(set !== null && { set }),
    enumerable: true,
    configurable: true
  })
}
