import { pzApiPostLoginDef, pzApiPrepareLoginDef } from './authLoginApiDefs'
import { pzApiPostLogoutDef, pzApiPrepareLogoutDef } from './authLogoutApiDefs'
import { pzApiPostRegistrationDef, pzApiPrepareRegistrationDef } from './authRegistrationApiDefs'
import { pzApiPostUserSettingsDef, pzApiPrepareUserSettingsDef } from './authUserSettingsApiDefs'
import { pzApiAuthWhoAmIDef } from './authWhoAmIApiDefs'

export * from './authBaseApiDefs'
export * from './authWhoAmIApiDefs'
export * from './authUserSettingsApiDefs'
export * from './authLoginApiDefs'
export * from './authLogoutApiDefs'
export * from './authRegistrationApiDefs'

// -------------------------------------------------------------------

export const pzApiAuthDef = {
  whoami: pzApiAuthWhoAmIDef,
  userSettings: {
    prepare: pzApiPrepareUserSettingsDef,
    post: pzApiPostUserSettingsDef
  },
  registration: {
    prepare: pzApiPrepareRegistrationDef,
    post: pzApiPostRegistrationDef
  },
  login: {
    prepare: pzApiPrepareLoginDef,
    post: pzApiPostLoginDef
  },
  logout: {
    prepare: pzApiPrepareLogoutDef,
    post: pzApiPostLogoutDef
  }
}
