import { t } from 'i18next'

import { timerService } from '@/services/timerService.ts'

import { appState } from './appState.svelte.ts'

// -------------------------------------------------------------------

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    anonymousWarningDelayMs: number
  }
}

// -------------------------------------------------------------------

const LOCAL_SAVING_DELAY = 2000 // Only show "Saving..." after 2 seconds.
const ANONYMOUS_WARNING_DELAY_MS = 15 * 60 * 1000 // Only show anonymous warning after 15 minutes after creating first item.
const SYNC_UI_UPDATE_INTERVAL = 1000 // 1 second
const SYNCING_DISPLAY_DURATION = 500 // Duration to show syncing indicator

window.anonymousWarningDelayMs = ANONYMOUS_WARNING_DELAY_MS

// -------------------------------------------------------------------

export interface SyncState {
  isLocalSaving: boolean
  isSyncing: boolean
  isOffline: boolean
  hasError: boolean
  errorMessage?: string
  syncEnabled: boolean
  isConnected: boolean
  firstItemCreatedAt?: number
  currentTm: number
}

// -------------------------------------------------------------------

export interface SyncUIState {
  color: '' | 'red' | 'orange' | 'yellow' | 'blue' | 'green' | 'green_lite'
  message: string
  exclaim?: boolean
}

// -------------------------------------------------------------------

const initialState: SyncState = {
  isLocalSaving: false,
  isSyncing: false,
  isOffline: false,
  hasError: false,
  errorMessage: undefined,
  syncEnabled: true,
  isConnected: true,
  firstItemCreatedAt: undefined,
  currentTm: Date.now()
}

// -------------------------------------------------------------------

export const syncState = $state(initialState)

// -------------------------------------------------------------------

timerService.addTask('syncUIUpdate', () => {
  // Force a UI update by triggering a state change.
  // This is needed because for some states (anonymous user) there is a time check.
  if (appState.isAnonymousUser) {
    updateSyncState({ currentTm: Date.now() })
  }
}, SYNC_UI_UPDATE_INTERVAL)

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
function determineUIState (baseState: SyncUIState): SyncUIState {
  if (syncState.hasError) {
    return {
      color: 'red',
      message: syncState.errorMessage || t('Unable to save'),
      exclaim: true
    }
  }

  if (syncState.isSyncing && syncState.isConnected) {
    return {
      color: 'blue',
      message: t('Updating everywhere...')
    }
  }

  if (syncState.isLocalSaving) {
    return {
      color: 'green_lite',
      message: t('Saving...')
    }
  }

  if (appState.isAnonymousUser) {
    return {
      color: 'orange',
      message: t('Saved on this device only'),
      exclaim: true
    }
  }

  if (!syncState.isConnected) {
    return {
      color: 'yellow',
      message: t('No internet')
    }
  }

  return baseState
}

// -------------------------------------------------------------------

export function getSyncUIState (): SyncUIState {
  const baseState: SyncUIState = {
    color: 'green',
    message: t('Data up to date')
  }

  if (appState.isAnonymousUser) {
    const timeSinceFirstItem = syncState.currentTm - (syncState.firstItemCreatedAt ?? syncState.currentTm)
    if (timeSinceFirstItem < window.anonymousWarningDelayMs) {
      return { color: '', message: t('') }
    }
  }

  const uiState = determineUIState(baseState)

  // log(4, 'syncUIState', uiState.color, { syncState, uiState })

  return uiState
}

// -------------------------------------------------------------------

function handleLocalSaving (isLocalSaving: boolean): void {
  if (isLocalSaving) {
    timerService.removeTask('localSaving')
    timerService.addTask('localSaving', () => {
      syncState.isLocalSaving = true
    }, LOCAL_SAVING_DELAY)
    return
  }

  timerService.removeTask('localSaving')
  syncState.isLocalSaving = false
}

// -------------------------------------------------------------------

function handleSyncing (): void {
  timerService.removeTask('syncingState')
  timerService.addTask('syncingState', () => {
    syncState.isSyncing = false
  }, SYNCING_DISPLAY_DURATION)
}

// -------------------------------------------------------------------

export function updateSyncState (newState: Partial<SyncState>): void {
  if (newState.isLocalSaving !== undefined) {
    handleLocalSaving(newState.isLocalSaving)
    return
  }

  if (newState.isSyncing === false && syncState.isSyncing) {
    handleSyncing()
    return
  }

  Object.assign(syncState, {
    ...newState,
    isOffline: newState.isConnected === false ? true : syncState.isOffline
  })
}

// -------------------------------------------------------------------

export function isSyncEnabled (): boolean {
  return syncState.syncEnabled && syncState.isConnected
}

// -------------------------------------------------------------------

export function setSyncEnabled (enabled: boolean): void {
  updateSyncState({ syncEnabled: enabled })
}

// -------------------------------------------------------------------

export function markFirstItemCreated (): void {
  if (!syncState.firstItemCreatedAt || syncState.firstItemCreatedAt === 0) {
    updateSyncState({ firstItemCreatedAt: Date.now() })
  }
}
