import type { UuidB62 } from '@utils/id'

import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiTribesSlug } from './tribesBaseApiDefs'

// -------------------------------------------------------------------
// External request type (used by the app)
// -------------------------------------------------------------------

export interface PzApiTribesRespondToInviteReqData extends PzApiBaseReqData {
  tribeId: UuidB62
  accept: boolean
}

// -------------------------------------------------------------------
// Internal request type (used within the API)
// -------------------------------------------------------------------

export interface PzApiTribesRespondToInviteReqDataInternal extends PzApiTribesRespondToInviteReqData {
  authenticatedUserId: UuidB62
}

export type PzApiTribesRespondToInviteResData = PzApiResDataSuccess | PzApiResDataFail

export const pzApiTribesRespondToInviteDef = {
  fetchUrl: `/${pzApiTribesSlug}/:tribeId/respond-to-invite`,
  fetchMethod: 'POST',
  reqData: {} as PzApiTribesRespondToInviteReqDataInternal,
  resData: {} as PzApiTribesRespondToInviteResData
} satisfies PzApiRoute<PzApiTribesRespondToInviteReqDataInternal, PzApiTribesRespondToInviteResData>
