import { pzApiSpacesAddUserDef } from './spacesAddUserApiDefs'
import { pzApiSpacesDeleteDef } from './spacesDeleteApiDefs'
import { pzApiSpacesInsertDef } from './spacesInsertApiDefs'
import { pzApiSpacesListDef } from './spacesListApiDefs'
import { pzApiSpacesUpdateDef } from './spacesUpdateApiDefs'
import { pzApiSpacesUpdateUserDef } from './spacesUpdateUserApiDefs'

export * from './spacesBaseApiDefs'
export * from './spacesListApiDefs'
export * from './spacesInsertApiDefs'
export * from './spacesDeleteApiDefs'
export * from './spacesAddUserApiDefs'
export * from './spacesUpdateUserApiDefs'
export * from './spacesUpdateApiDefs'

// -------------------------------------------------------------------

export const pzApiSpacesDef = {
  list: pzApiSpacesListDef,
  insert: pzApiSpacesInsertDef,
  update: pzApiSpacesUpdateDef,
  delete: pzApiSpacesDeleteDef,
  addUser: pzApiSpacesAddUserDef,
  updateUser: pzApiSpacesUpdateUserDef
}
