import { timerService } from '@/services/timerService.ts'
import { appState } from '@/store/appState.svelte.ts'
import { syncSpacesFromServer } from '@/store/space.svelte.ts'
import { updateSyncState } from '@/store/syncState.svelte.ts'
import { syncTribesFromServer } from '@/store/tribe.svelte.ts'
import { syncUsersFromServer } from '@/store/user.svelte.ts'
import { ensuredDefined } from '@/utils/error.ts'

// -------------------------------------------------------------------

const ENTITY_SYNC_POLL_INTERVAL = 30000 // 30 seconds

// -------------------------------------------------------------------

function canStartPolling (): boolean {
  return Boolean(
    !!appState.authenticatedUserId &&
    !!window.syncEnabled &&
    appState.isSpacesKnown &&
    appState.isTribesKnown
  )
}

// -------------------------------------------------------------------

export function startEntityPolling (): void {
  const pollTaskId = 'entityPoll'

  // Remove any existing polling task
  timerService.removeTask(pollTaskId)

  // Add new polling task
  timerService.addTask(pollTaskId, async () => {
    if (!canStartPolling()) return

    try {
      updateSyncState({ isSyncing: true })

      const userId = ensuredDefined(appState.authenticatedUserId, 'appState.authenticatedUserId not initialized')

      // Sequential requests to reduce server load
      await syncSpacesFromServer(userId)
      await syncTribesFromServer(userId)
      await syncUsersFromServer() // TODO We should use an etag for all 3 requests, to make sure we are not constantly sending the same data.
    } catch (error) {
      console.error('Entity sync error:', error)
    } finally {
      updateSyncState({ isSyncing: false })
    }
  }, ENTITY_SYNC_POLL_INTERVAL)
}
