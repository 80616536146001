<script lang="ts">
import { type Component } from 'svelte'
import type { Editor } from '@tiptap/core'

import type { EditorState } from '@/utils/editor.ts'

import { getEditorToolbarDef } from './ZEditorToolbarSettings.ts'

import ZIcon from './ZIcon.svelte'

// -------------------------------------------------------------------

const {
  editor,
  editorState,
  showAdvanced = false,
  ...props
}: {
  editor: Editor
  editorState: EditorState
  showAdvanced?: boolean
} = $props()

const items = getEditorToolbarDef(editor)

// -------------------------------------------------------------------

type Test = (() => void) | ((v: any) => void)
</script>

<!-- ------------------------------------------------------------- -->

{#snippet toolbarItem(icon: Component, isActive: string, action: Test)}
  <div class="[&_.z-icon:hover]:text-highlight [&_.z-icon.is-active]:text-pz_gray-darkmid mr-8">
    <ZIcon
      class={(((editorState[isActive] as boolean) ?? false) ? 'is-active' : '') + ' size-5 text-pz_gray-midlight'}
      {icon}
      onclick={action as () => void}
    ></ZIcon>
  </div>
{/snippet}

<!-- ------------------------------------------------------------- -->

<div class="bg-pz_gray-bright flex flex-row rounded-t-[12px] px-5 py-4">
  {#each items as group, index (index)}
    <!-- ==<LogPreview dat={group} title="ToolbarDefGroup" /> -->
    {#if true || (!showAdvanced && group.advanced !== true) || (showAdvanced && group.advanced !== false)}
      <div class="nux-tb-group flex flex-row">
        <div class="divider"></div>
        {#each group.items as item, index2 (index2)}
          <!-- ##<LogPreview dat={item} title="ToolbarDefItem" /> -->
          {#if true || (!showAdvanced && item.advanced !== true) || (showAdvanced && item.advanced !== false)}
            {#if item.type === 'divider'}
              <div class="divider"></div>
            {:else}
              {@render toolbarItem(item.icon, item.isActive, item.action)}
            {/if}
          {/if}
        {/each}
      </div>
    {/if}
  {/each}
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.divider
  width: 2px;
  height: 1.25rem;
  background-color: rgba(#000, 0.1);
  margin-left: 0.6rem;
  margin-right: 0.3rem;

.nux-tb-group:first-child
  .divider
    display none !important

.nux-arrow-dropdown
  margin 0 -6px
  color #888
</style>
