<script lang="ts">
import type { SvelteComponent } from 'svelte'
import { t } from 'i18next'

import { callHooksMainMenuList, type MainMenuList } from '@/modules/hooks.ts'

import { appState } from '../store/appState.svelte.ts'

import { logout } from '../store/auth.svelte.ts'

import { gotoUrl } from '../routes.svelte.ts'

import AppSideMenu from './AppSideMenu.svelte'

import NuIconHouse from './icons/NuIconHouse.svelte'
import NuIconLogout from './icons/NuIconLogout.svelte'
import NuIconUser from './icons/NuIconUser.svelte'
import NuIconUserGroup from './icons/NuIconUserGroup.svelte'

// -------------------------------------------------------------------

interface MenuComponents {
  List: typeof SvelteComponent
  ListItem: typeof SvelteComponent
}

// -------------------------------------------------------------------

const { onClose } = $props()

let sideMenuComponent: AppSideMenu

// -------------------------------------------------------------------

let showMainMenu2 = $state(false)

// -------------------------------------------------------------------

const mainMenuList: MainMenuList[] = []

callHooksMainMenuList(mainMenuList)

// -------------------------------------------------------------------

function openMainMenu2 (): void {
  showMainMenu2 = true
}

// -------------------------------------------------------------------

function closeMainMenu2 (): void {
  showMainMenu2 = false
}

// -------------------------------------------------------------------

async function doLogout (): Promise<void> {
  await logout()
  gotoUrl('/login')
}

// -------------------------------------------------------------------

function doLogoutAndClose (): void {
  void doLogout()
  sideMenuComponent.initClose()
}

// -------------------------------------------------------------------

function goRouteAndClose (slug: string): void {
  gotoUrl(slug)
  sideMenuComponent.initClose()
}
</script>

<!-- ------------------------------------------------------------- -->

<div class="a-app-side-menu-main flex flex-col">
  <AppSideMenu bind:this={sideMenuComponent} {onClose} title={t('Menu')}>
    {#snippet children(components: MenuComponents)}
      {@const { List, ListItem } = components}
      <List>
        {#each mainMenuList as { icon, title, slug }, _index (slug)}
          <ListItem
            {icon}
            onclick={() => {
              goRouteAndClose(slug)
            }}
            {title}
          />
        {/each}

        <ListItem divider={true} />

        {#if appState.isAnonymousUser}
          <ListItem
            icon={NuIconUser}
            onclick={() => {
              goRouteAndClose('/register')
            }}
            title={t('Register')}
          />
          <ListItem
            icon={NuIconUser}
            onclick={() => {
              goRouteAndClose('/login')
            }}
            title={t('Login')}
          />
        {:else}
          <ListItem
            icon={NuIconUser}
            onclick={() => {
              goRouteAndClose('/profile')
            }}
            title={t('Your account') + ' - ' + appState.authenticatedUser?.displayName}
          />
          <ListItem
            icon={NuIconUserGroup}
            onclick={() => {
              goRouteAndClose('/invite')
            }}
            title={t('Invite users')}
          />
          <ListItem icon={NuIconLogout} onclick={doLogoutAndClose} title={t('Logout')} />
        {/if}

        <ListItem divider={true} />

        <ListItem
          icon={NuIconHouse}
          onclick={() => {
            goRouteAndClose('/')
          }}
          title={t('Home')}
        />
        <ListItem
          icon={NuIconHouse}
          onclick={() => {
            goRouteAndClose('/test')
          }}
          title={t('Test page')}
        />
        <ListItem icon={NuIconHouse} onclick={openMainMenu2} title={t('Test 2nd menu')} />
      </List>
    {/snippet}
  </AppSideMenu>
</div>

{#if showMainMenu2}
  <AppSideMenu on-Close={closeMainMenu2} title={t('Test')}>
    Main 444 menu<br />
  </AppSideMenu>
{/if}
