/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { PzApiResData, PzApiRoute } from '../apiDefs'

import type { PzApiKratosReqData, PzApiKratosResData } from './authBaseApiDefs'
import { pzApiAuthSlug } from './authBaseApiDefs'

// -------------------------------------------------------------------

const pzApiSettingsSlug = 'settings'

// -------------------------------------------------------------------

export interface PzApiAuthPostUserSettingsData {
  displayName: string
  email: string
}

// -------------------------------------------------------------------

export interface PzApiAuthPostUserSettingsReqData extends PzApiKratosReqData, PzApiAuthPostUserSettingsData {}

// -------------------------------------------------------------------

export const pzApiPrepareUserSettingsDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiSettingsSlug}`,
  fetchMethod: 'GET',
  includeContext: true,
  reqData: {} as PzApiKratosReqData,
  resData: {} as PzApiKratosResData
} satisfies PzApiRoute<PzApiKratosReqData, PzApiKratosResData>

// -------------------------------------------------------------------

export const pzApiPostUserSettingsDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiSettingsSlug}`,
  fetchMethod: 'POST',
  includeContext: true,
  reqData: {} as PzApiAuthPostUserSettingsReqData,
  resData: {} as PzApiResData
} satisfies PzApiRoute<PzApiAuthPostUserSettingsReqData, PzApiResData>
