<script lang="ts">
  import type { Component, Snippet } from 'svelte'

  import { cn } from '../utils/shadcnUtils.ts'

  import ZTabs from './ZTabs.svelte'

  import ZIcon from './ZIcon.svelte'

  // -------------------------------------------------------------------

  const {
    children,
    gutter,
    roundedTop = true,
    tabs,
    title,
    icon
  }: {
    children: Snippet
    gutter?: Snippet
    roundedTop?: boolean
    tabs?: {
      activeId: string
      tabs: Array<{ id: string, label: string }>
      tabClick: (_tabId: string) => void
    }
    title?: string
    icon?: Component
  } = $props()

  // -------------------------------------------------------------------

  const mainPanelClasses = cn(
    'max-mobile:rounded-t-3xl max-mobile:rounded-bl-none',
    'flex grow flex-col bg-white px-7 py-5',
    {
      'rounded-tl-3xl': roundedTop && gutter && !tabs && !title,
      'rounded-t-3xl': roundedTop && !gutter && !tabs && !title,
      'rounded-t-none': tabs || title,
      'rounded-bl-3xl': true,
      'rounded-br-3xl': !gutter
    }
  )
</script>

<!-- ------------------------------------------------------------- -->

<div class="flex flex-row">
  <div class="max-mobile:flex-col flex grow flex-row">
    <div class="flex w-full flex-col">
      <div class="bg-pz_gray-bright flex items-center gap-1 rounded-t-3xl p-2 pl-7">
        {#if title}
          <div class="flex items-center gap-2.5 py-2 text-gray-900">
            {#if icon}
              <ZIcon class="size-5 translate-y-[1px]" {icon} onclick={() => {}} />
            {/if}
            <h2 class="font-body text-xl font-bold">{title}</h2>
          </div>
        {/if}
        {#if tabs}
          <div class="ml-4">
            <ZTabs
              class="!p-0"
              activeId={tabs.activeId}
              tabClick={tabs.tabClick}
              tabs={tabs.tabs}
            />
          </div>
        {/if}
      </div>

      <div class="flex flex-row">
        <div class={mainPanelClasses}>
          {@render children()}
        </div>

        {#if gutter}
          <div
            class="bg-pz_gray-bright max-mobile:rounded-b-3xl max-mobile:rounded-tr-none
              flex min-w-[180px] rounded-br-3xl px-7 py-6"
          >
            {@render gutter()}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
