<script lang="ts">
  import { t } from 'i18next'

  import { pzApiSrvAddUserToTribe, pzApiSrvRespondToInvite, pzApiSrvUpdateUserRights } from '@/services/pzApiSrv.ts'
  import { appState } from '@/store/appState.svelte.ts'
  import { setActiveTribe } from '@/store/tribe.svelte.ts'
  import { type UuidB62 } from '@/utils/id.ts'

  import ZButton from '../../components/ZButton.svelte'
  import ZInput from '../../components/ZInput.svelte'
  import AppTitle from '../AppTitle.svelte'
  // import LogPreview from '../LogPreview.svelte'
  import ZPanel from '../ZPanel.svelte'
  import ZPanelHeadline from '../ZPanelHeadline.svelte'

  import NuIconUserGroup from '../icons/NuIconUserGroup.svelte'

  // -------------------------------------------------------------------

  const tribe = $derived(appState.activeTribe)
  const userId = $derived(appState.authenticatedUserId)
  const tribes = $derived(appState.tribes)
  const activeTribeId = $derived(tribe?.id)
  const isOwner = $derived(tribe?.users && userId ? tribe.users[userId]?.rights === 'o' : false)

  // -------------------------------------------------------------------

  function getUserDisplayName (userIdToLookup: UuidB62): string {
    return appState.users?.[userIdToLookup]?.displayName ?? userIdToLookup
  }

  // -------------------------------------------------------------------

  const activeMembers = $derived(
    tribe?.users
      ? Object.entries(tribe.users)
        .filter(([_key, user]) => user.rights !== 'p')
        .map(([memberId, user]) => ({
          userId: memberId,
          rights: user.rights,
          id: user.id,
          displayName: getUserDisplayName(user.id)
        }))
      : []
  )

  // -------------------------------------------------------------------

  // Pending invites computation
  const pendingInvites = $derived(
    tribe?.users
      ? Object.entries(tribe.users)
        .filter(([_key, user]) => user.rights === 'p')
        .map(([_, user]) => ({
          email: user.inviteeEmail ?? '',
          rights: user.rights,
          inviterId: user.id
        }))
      : []
  )

  // -------------------------------------------------------------------

  let inviteEmail = $state('')
  let error = $state<string | null>(null)
  let successMessage = $state<string | null>(null)
  // let newUserRights = $state('r')

  // -------------------------------------------------------------------

  // eslint-disable-next-line max-statements
  async function addUser (): Promise<void> {
    if (!inviteEmail) {
      error = t('Please enter an email address')
      return
    }

    error = null
    successMessage = null
    try {
      if (!tribe) {
        throw new Error('No active tribe selected')
      }
      // const userId = newUuidB62()
      // await pzApiSrvAddUserToTribe(tribe.id, userId, inviteEmail, 'p')
      await pzApiSrvAddUserToTribe(tribe.id, null, inviteEmail, 'p')
      inviteEmail = ''
      successMessage = t('Invitation sent')
    } catch (err) {
      error = err instanceof Error ? err.message : 'Failed to add user'
    }
  }

  // -------------------------------------------------------------------

  async function updateUserRights (memberUserId: UuidB62, rights: 'r' | 'w'): Promise<void> {
    if (!tribe?.id) return

    error = null
    try {
      await pzApiSrvUpdateUserRights(tribe.id, memberUserId, rights)
    } catch (err) {
      error = err instanceof Error ? err.message : 'Failed to update user rights'
    }
  }

  // -------------------------------------------------------------------

  const tribeTabs = $derived(
    Object.entries(tribes ?? {}).map(([id, tribeItem]) => ({
      id,
      label: tribeItem.displayName ?? tribeItem.name
    }))
  )

  // -------------------------------------------------------------------

  function handleTabClick (tabId: string): void {
    setActiveTribe(tabId as UuidB62)
  }

  // -------------------------------------------------------------------

  async function acceptInvite (tribeId: UuidB62): Promise<void> {
    error = null
    successMessage = null
    try {
      const result = await pzApiSrvRespondToInvite(tribeId, true)
      if (!result.ok) {
        throw new Error(result.errMsg || 'Failed to accept invite')
      }
      successMessage = t('Invite accepted')
    } catch (err) {
      error = err instanceof Error ? err.message : 'Failed to accept invite'
    }
  }

  // -------------------------------------------------------------------

  async function declineInvite (tribeId: UuidB62): Promise<void> {
    error = null
    successMessage = null
    try {
      const result = await pzApiSrvRespondToInvite(tribeId, false)
      if (!result.ok) {
        throw new Error(result.errMsg || 'Failed to decline invite')
      }
      successMessage = t('Invite declined')
    } catch (err) {
      error = err instanceof Error ? err.message : 'Failed to decline invite'
    }
  }

  // -------------------------------------------------------------------

  const myInvites = $derived(
    Object.entries(tribes ?? {}).flatMap(([_tribeId, tribeItem]) =>
      Object.entries(tribeItem.users ?? {})
        .filter(([_userId, user]) =>
          user.rights === 'p' && // Only pending invites, not declined ones
            user.inviteeEmail === appState.authenticatedUser?.email
        )
        .map(([_userId, user]) => ({
          tribeName: tribeItem.displayName ?? tribeItem.name,
          tribeId: tribeItem.id,
          rights: user.rights,
          inviterId: user.id,
          inviterName: getUserDisplayName(user.id)
        }))
    )
  )
</script>

<!-- ------------------------------------------------------------- -->

<AppTitle icon={NuIconUserGroup} title={t('Manage Team')} />

<!-- ------------------------------------------------------------- -->

<!-- <LogPreview dat={myInvites} title="myInvites" /> -->

<!-- New section for user's invites -->
{#if myInvites.length > 0}
  <div class="mb-4">
    <ZPanel title={t('You have been invited')}>
      <div class="space-y-2">
        {#each myInvites as invite (invite.tribeId)}
          <div class="flex items-center justify-between rounded-lg bg-blue-50 p-3">
            <div>
              <div class="font-medium text-gray-700">
                {t('Team')}: {invite.tribeName}
              </div>
              <div class="text-sm text-gray-500">
                {t('Invited by')}: {invite.inviterName}
              </div>
              <div class="text-sm text-gray-500">
                {t('Access')}: {invite.rights === 'r' ? t('Read') : t('Write')}
              </div>
            </div>
            <div class="flex gap-2">
              <ZButton
                onclick={() => acceptInvite(invite.tribeId)}
                size="sm"
                variant="success"
              >
                {t('Accept')}
              </ZButton>
              <ZButton
                onclick={() => declineInvite(invite.tribeId)}
                size="sm"
                variant="danger"
              >
                {t('Decline')}
              </ZButton>
            </div>
          </div>
        {/each}
      </div>
    </ZPanel>
  </div>
{/if}

<!-- ------------------------------------------------------------- -->

<ZPanel
  tabs={{
    activeId: activeTribeId ?? '',
    tabClick: handleTabClick,
    tabs: tribeTabs
  }}
  title={t(tribeTabs.length === 1 ? 'Team' : 'Teams')}
>
  <div class="p-0">
    {#if error}
      <div class="mb-4 rounded bg-red-100 p-3 text-red-700">
        {error}
      </div>
    {/if}

    {#if successMessage}
      <div class="mb-4 rounded bg-green-100 p-3 text-green-700">
        {successMessage}
      </div>
    {/if}

    <!-- Invite New Member Section -->
    {#if isOwner}
      <div class="mb-6 mt-2">
        <ZPanelHeadline title={t('Invite New Member')} />
        <div class="space-y-4">
          <div class="flex gap-4">
            <div class="grow">
              <ZInput
                placeholder={t('Email-address of the new user')}
                type="email"
                bind:value={inviteEmail}
              />
            </div>
            <!-- <div class="flex gap-4">
              <label class="flex items-center gap-2">
                <input
                  name="userRights"
                  class="h-4 w-4 text-blue-600"
                  type="radio"
                  value="r"
                  bind:group={newUserRights}
                />
                <span>{t('Read access')}</span>
              </label>
              <label class="flex items-center gap-2">
                <input
                  name="userRights"
                  class="h-4 w-4 text-blue-600"
                  type="radio"
                  value="w"
                  bind:group={newUserRights}
                />
                <span>{t('Write access')}</span>
              </label>
            </div> -->
            <ZButton onclick={addUser}>{t('Invite user')}</ZButton>
          </div>
        </div>
      </div>
    {/if}

    <!-- Active Members Section -->
    <div class="mb-6">
      <ZPanelHeadline title={t('Team Members') + ` (${activeMembers.length})`} />
      <div class="space-y-2">
        {#each activeMembers as member (member.userId)}
          <div class="flex items-center justify-between rounded-lg bg-gray-50 p-3">
            <div class="text-gray-700">
              {#if member.userId === appState.authenticatedUserId}
                {t('You')}
              {:else}
                {member.displayName}
              {/if}
            </div>
            {#if isOwner && member.userId !== appState.authenticatedUserId}
              <div class="flex items-center gap-2">
                <select
                  class="rounded border p-1"
                  onchange={(e) => updateUserRights(member.userId as UuidB62, e.currentTarget.value as 'r' | 'w')}
                  value={member.rights}
                >
                  <option value="r">{t('Read')}</option>
                  <option value="w">{t('Write')}</option>
                </select>
              </div>
            {:else}
              <div class="text-sm text-gray-500">
                {#if member.rights === 'o'}
                  {t('Owner')}
                {:else if member.rights === 'w'}
                  {t('Write')}
                {:else}
                  {t('Read')}
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      </div>
    </div>

    <!-- Pending Invites Section -->
    {#if pendingInvites.length > 0}
      <div class="mb-6">
        <ZPanelHeadline title={t('Pending Invites')} />
        <div class="space-y-2">
          {#each pendingInvites as invite (invite.email)}
            <div class="flex items-center justify-between rounded-lg bg-yellow-50 p-3">
              <div>
                <div class="pz-invite-item-email text-gray-700">{invite.email}</div>
                <div class="text-sm text-gray-500">
                  {t('Access')}: {invite.rights === 'r' ? t('Read') : t('Write')}
                </div>
              </div>
              <div class="text-sm text-gray-500">
                {t('Pending')}
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>
</ZPanel>
