<script lang="ts">
import { t } from 'i18next'

import { appState } from '../store/appState.svelte.ts'

import { syncState } from '../store/syncState.svelte.ts'

import ZButton from '../components/ZButton.svelte'
import { gotoUrl } from '../routes.svelte.ts'

import NuIconPrayer from '@icons/NuIconPrayer.svelte'
import NuIconUser from '@icons/NuIconUser.svelte'
import NuIconUserDetails from '@icons/NuIconUserDetails.svelte'

// -------------------------------------------------------------------

// Use firstItemCreatedAt from syncState to determine if user has data
const hasData = $derived(syncState.firstItemCreatedAt != null && syncState.firstItemCreatedAt > 0)

// -------------------------------------------------------------------

function getStarted (): void {
  gotoUrl('/contacts/new')
}
</script>

<div class="flex flex-col items-center justify-center p-8 text-center">
  {#if !appState.isLoggedInAndIdKnown || appState.isAnonymousUser}
    {#if !hasData}
      <!-- Case 1: Anonymous, no data -->
      <h1 class="mb-6 text-3xl font-bold">{t('Welcome to Peanutz')}</h1>
      <p class="mb-8 max-w-lg text-gray-600">
        {t('Start organizing your contacts and prayers right away. No registration required to try it out!')}
      </p>
      <div class="flex gap-4">
        <ZButton icon={NuIconUserDetails} onclick={getStarted}>
          {t('Get Started')}
        </ZButton>
        <ZButton icon={NuIconUser} onclick={() => gotoUrl('/register')} variant="outline">
          {t('Create Account')}
        </ZButton>
      </div>
    {:else}
      <!-- Case 2: Anonymous with data -->
      <h1 class="mb-6 text-3xl font-bold">{t('Welcome Back')}</h1>
      <p class="mb-8 max-w-lg text-gray-600">
        {t('Continue working with your data or create an account to ensure it\'s safely stored.')}
      </p>
      <div class="flex gap-4">
        <ZButton icon={NuIconPrayer} onclick={() => gotoUrl('/prayers')}>
          {t('Your Prayers')}
        </ZButton>
        <ZButton icon={NuIconUserDetails} onclick={() => gotoUrl('/contacts')}>
          {t('Your Contacts')}
        </ZButton>
        <ZButton icon={NuIconUser} onclick={() => gotoUrl('/register')} variant="outline">
          {t('Create Account')}
        </ZButton>
      </div>
    {/if}
  {:else}
    {#if !hasData}
      <!-- Case 3: Authenticated, no data -->
      <h1 class="mb-6 text-3xl font-bold">{t('Welcome to Your Space')}</h1>
      <p class="mb-8 max-w-lg text-gray-600">
        {t('Start by adding your first contact or prayer request.')}
      </p>
      <div class="flex gap-4">
        <ZButton icon={NuIconUserDetails} onclick={() => gotoUrl('/contacts/new')}>
          {t('Add Contact')}
        </ZButton>
        <ZButton icon={NuIconPrayer} onclick={() => gotoUrl('/prayers/new')}>
          {t('Add Prayer')}
        </ZButton>
      </div>
    {:else}
      <!-- Case 4: Authenticated with data -->
      <h1 class="mb-6 text-3xl font-bold">{t('Welcome Back')}</h1>
      <div class="mb-8 grid grid-cols-2 gap-8">
        <div class="rounded-lg bg-gray-50 p-6">
          <h2 class="mb-4 text-xl font-semibold">{t('Recent Contacts')}</h2>
          <!-- TODO: Add recent contacts list component -->
        </div>
        <div class="rounded-lg bg-gray-50 p-6">
          <h2 class="mb-4 text-xl font-semibold">{t('Recent Prayers')}</h2>
          <!-- TODO: Add recent prayers list component -->
        </div>
      </div>
      <div class="flex gap-4">
        <ZButton icon={NuIconUserDetails} onclick={() => gotoUrl('/contacts')}>
          {t('All Contacts')}
        </ZButton>
        <ZButton icon={NuIconPrayer} onclick={() => gotoUrl('/prayers')}>
          {t('All Prayers')}
        </ZButton>
      </div>
    {/if}
  {/if}
</div>
