/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiTribesSlug } from './tribesBaseApiDefs'

// -------------------------------------------------------------------

export interface PzApiTribesUpdateUserReqData extends PzApiBaseReqData {
  tribeId: UuidB62
  userId: UuidB62
  rights: 'w' | 'r'
}

// -------------------------------------------------------------------

export type PzApiTribesUpdateUserResData =
  | (PzApiResDataSuccess & { updated: boolean })
  | (PzApiResDataFail & { updated: boolean })

// -------------------------------------------------------------------

export const pzApiTribesUpdateUserDef = {
  fetchUrl: `/${pzApiTribesSlug}/update_user`,
  fetchMethod: 'POST',
  reqData: {} as PzApiTribesUpdateUserReqData,
  resData: {} as PzApiTribesUpdateUserResData
} satisfies PzApiRoute<PzApiTribesUpdateUserReqData, PzApiTribesUpdateUserResData>
