/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { pzApiTribe } from '../../tribes'
import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { pzApiTribesSlug } from './tribesBaseApiDefs'

// -------------------------------------------------------------------
// External request type (used by the app)
// -------------------------------------------------------------------

export interface PzApiTribesInsertReqData {
  tribeId: UuidB62
  name: string
}

// -------------------------------------------------------------------
// Internal request type (used within the API)
// -------------------------------------------------------------------

export interface PzApiTribesInsertReqDataInternal extends PzApiTribesInsertReqData {
  authenticatedUserId: UuidB62
}

// -------------------------------------------------------------------

export type PzApiTribesInsertResData =
  | (PzApiResDataSuccess & { tribes: pzApiTribe[] })
  | (PzApiResDataFail & { tribes: pzApiTribe[] })

// -------------------------------------------------------------------

export const pzApiTribesInsertDef = {
  fetchUrl: `/${pzApiTribesSlug}/insert`,
  fetchMethod: 'PUT',
  reqData: {} as PzApiTribesInsertReqDataInternal,
  resData: {} as PzApiTribesInsertResData
} satisfies PzApiRoute<PzApiTribesInsertReqDataInternal, PzApiTribesInsertResData>
