import { dexieGetAllMorphs } from '@/utils/dexie.ts'
import { ensuredDefined } from '@/utils/error.ts'
import { ydbInitDB } from '@/utils/ydb.ts'
import { type YDB } from '@/utils/ydb.ts'

import { appState, type UseStateReturn } from './appState.svelte.ts'

import { markFirstItemCreated, updateSyncState } from './syncState.svelte.ts'

// -------------------------------------------------------------------

export interface UserDbState {
  userDb: YDB
}

// -------------------------------------------------------------------

export function useUserDb (): UseStateReturn {
  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      $effect.root(() => {
        $effect(() => {
          // Initialize database for both authenticated and anonymous users
          if (appState.authenticatedUserId != null && appState.userDb === undefined) {
            // Initialize the database using localDbId
            appState.userDb = ydbInitDB(
              ensuredDefined(appState.localDbId, 'appState.localDbId not initialized'),
              ensuredDefined(appState.instance, 'appState.instance not initialized')
            )

            // Check for existing data and set firstItemCreatedAt if needed
            void dexieGetAllMorphs(appState.userDb.db).then(morphs => {
              if (morphs.length > 0) {
                // Set firstItemCreatedAt to 24 hours ago to ensure warning shows immediately
                markFirstItemCreated()
                // Force the timestamp to be old enough to show the warning
                updateSyncState({ firstItemCreatedAt: Date.now() - 24 * 60 * 60 * 1000 })
              }
            })
          }
        })
      })
    },
    extendState (): ReturnType<UseStateReturn['extendState']> {}
  }
}
