import type { UuidB62 } from '@utils/id'

import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiUsersSlug } from './usersBaseApiDefs.ts'

// -------------------------------------------------------------------

export interface PzApiUsersDetailsReqData extends PzApiBaseReqData {
  userIds: UuidB62[]
}

// -------------------------------------------------------------------

export interface UserDetails {
  id: UuidB62
  email?: string
  displayName?: string
}

// -------------------------------------------------------------------

export type PzApiUsersDetailsResData =
  | (PzApiResDataSuccess & { users: UserDetails[] })
  | PzApiResDataFail

// -------------------------------------------------------------------

export const pzApiUsersDetailsDef = {
  fetchUrl: `/${pzApiUsersSlug}/details`,
  fetchMethod: 'POST',
  reqData: {} as PzApiUsersDetailsReqData,
  resData: {} as PzApiUsersDetailsResData
} satisfies PzApiRoute<PzApiUsersDetailsReqData, PzApiUsersDetailsResData>
