import { type DbMorph } from '@utils/dexie'
import { ensuredDefined } from '@utils/error'
import { newUuidB62, type UuidB62 } from '@utils/id'
import type { YDoc } from '@utils/y'
import {
  yDocCreate,
  yDocOnUpdate,
  yDocSetKeyValue,
  // yDocGetKeyValue,
  // yDocApplyUpdate,
  // yDocSyncJsObjectToYDoc,
  yDocToJsObject
} from '@utils/y'
import { ydbGetAllByBreedAndApplyMorphs, ydbGetOneByItemIdAndApplyMorphs, ydbMorph } from '@utils/ydb'

import { appState } from './appState.svelte.ts'

import { markFirstItemCreated } from './syncState.svelte.ts'

// -------------------------------------------------------------------

function itemGetNewReactiveItem (): { ival: Record<string, unknown> } {
  let item = $state({})

  return {
    get ival () {
      return item
    },
    set ival (v) {
      item = v
    }
  }
}

// -------------------------------------------------------------------

export function itemGetNewId (): UuidB62 {
  return newUuidB62()
}

// -------------------------------------------------------------------

function itemProcessYUpdate (doc: YDoc, update: Uint8Array, origin: unknown): void {
  const instance = ensuredDefined(appState.instance, 'appState.instance not initialized')
  const userDb = ensuredDefined(appState.userDb, 'appState.userDb not initialized')

  // console.log('itemProcessYUpdate yDocOnUpdate', doc, update, origin)

  markFirstItemCreated()

  if (origin !== 'applyfromdb') {
    const dat: DbMorph = {
      id: newUuidB62(),
      itemId: doc.id,
      instanceId: instance.id,
      spaceId: doc.spaceId,
      breed: doc.breed,
      ky: '',
      instanceClock: 1,
      ymut: update
    }
    // console.log('itemProcessYUpdate yDocOnUpdate 222', dat)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises
    ydbMorph(userDb, dat)
  }

  // console.log('itemProcessYUpdate yDocOnUpdate 444', yDocToJsObject(doc))
  doc.item.ival = yDocToJsObject(doc)
}

// -------------------------------------------------------------------

// TODO Merge with function above.
export function itemProcessYSubDocUpdate ({
  doc,
  update,
  origin,
  ky
}: {
  doc: YDoc
  update: Uint8Array
  origin: unknown
  ky: string
}): void {
  const instance = ensuredDefined(appState.instance, 'appState.instance not initialized')
  const userDb = ensuredDefined(appState.userDb, 'appState.userDb not initialized')

  // console.log('itemProcessYSubDocUpdate yDocOnUpdate', doc, update, origin)

  if (origin !== 'applyfromdb' && ky.startsWith('#')) {
    const dat: DbMorph = {
      id: newUuidB62(),
      itemId: doc.id,
      instanceId: instance.id,
      spaceId: doc.spaceId,
      breed: doc.breed,
      ky,
      instanceClock: 1,
      ymut: update
    }
    // console.log('itemProcessYUpdate yDocOnUpdate 222', dat)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises
    ydbMorph(userDb, dat)
  }
}

// -------------------------------------------------------------------

interface ItemGetByIdOrCreateNewParams {
  spaceId: UuidB62
  breed: string
  itemId: UuidB62
  loadData?: boolean | string
}

export function itemGetByIdOrCreateNew ({
  spaceId,
  breed,
  itemId,
  loadData = false
}: ItemGetByIdOrCreateNewParams): YDoc {
  const userDb = ensuredDefined(appState.userDb, 'appState.userDb not initialized')

  const doc = yDocCreate({
    spaceId,
    breed,
    itemId,
    item: itemGetNewReactiveItem()
  })

  // console.log('itemGetByIdOrCreateNew 000', spaceId, breed, itemId, doc)

  if (loadData !== false) {
    void ydbGetOneByItemIdAndApplyMorphs({ db: userDb, itemId, doc, subscribe: loadData === 'subscribe' })
  }

  yDocOnUpdate(doc, (update: Uint8Array, origin: unknown) => {
    itemProcessYUpdate(doc, update, origin)
  })

  // yDocSetKeyValue(doc, 'test', 'test_value')

  return doc
}

// -------------------------------------------------------------------

export function itemProcessChange (doc: YDoc, newData: Record<string, unknown>, path: string = ''): void {
  // console.log('itemProcessChange', doc, newData)

  // TODO Do this loop in 1 Y batch?
  // TODO Throttle?
  // TODO Merge changes with older updates that are not yet synced?
  for (const [ky, val] of Object.entries(newData)) {
    const fullKy = path + (path === '' ? '' : '.') + ky
    if (typeof val === 'object') {
      itemProcessChange(doc, val as Record<string, unknown>, fullKy)
    } else {
      yDocSetKeyValue(doc, fullKy, val)
    }
  }
}

// -------------------------------------------------------------------

export async function itemGetAllByBreed ({
  spaceId,
  breed,
  docs,
  loadData = false
}: {
  spaceId: UuidB62
  breed: string
  docs: YDoc[]
  loadData?: boolean | string
}): Promise<YDoc[]> {
  const userDb = ensuredDefined(appState.userDb, 'appState.userDb not initialized')

  await ydbGetAllByBreedAndApplyMorphs({
    db: userDb,
    breed,
    docs,
    subscribe: loadData === 'subscribe',
    callbackItemCreate: (itemId: UuidB62): YDoc => {
      return itemGetByIdOrCreateNew({
        spaceId,
        breed,
        itemId,
        loadData: false // TODO Does this specifically need to be false (because we are getting all items)? Or can it be the functions loadData param?
      })
    }
  })

  return docs
}
