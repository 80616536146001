import { base64ToBytes, bytesToBase64 } from '@/utils/base64.ts'
import type { Branded } from '@/utils/brandedType.ts'
import { type DbMorph } from '@/utils/dexie.ts'
import { type UuidB62 } from '@/utils/id.ts'

import type { Space } from '../store/space.ts'

import { log } from '../utils/debug.ts'

import { serverManager } from './serverManager.svelte.ts'

// -------------------------------------------------------------------

type YmutBase64 = Branded<string, 'YmutBase64'>

export interface SrvMorph {
  id: string
  instanceId: string
  spaceId: string
  folderId: string
  itemId: string
  breed: string
  ky: string
  instanceClock: number
  ymut: YmutBase64
}

// -------------------------------------------------------------------

interface MorphSrvSpace {
  space: Space
  lastPollTime: number
  pollInterval: number
}

export interface MorphSrvClient {
  spaces: Record<string, MorphSrvSpace>
}

// -------------------------------------------------------------------

interface MorphSrvGetSpaceMorphsResult {
  spaceId: string
  morphs: SrvMorph[]
}

// -------------------------------------------------------------------

export function getMorphSrvForSpace (spaceId: UuidB62 | null): { url: string, pollInterval: number } {
  const server = spaceId ? serverManager.getSyncServer(spaceId) : serverManager.getHubServer()
  return {
    url: server.url,
    pollInterval: server.pollInterval
  }
}

// -------------------------------------------------------------------

export function morphSrvCreateClient (_serverInfo: { url: string, pollInterval: number }): MorphSrvClient {
  return {
    spaces: {}
  }
}

// -------------------------------------------------------------------

function morphSrvCheckSpaceExists (srv: MorphSrvClient, space: Space): boolean {
  return srv.spaces[space.id] !== undefined
}

// -------------------------------------------------------------------

export function morphSrvCheckAddSpace (srv: MorphSrvClient, space: Space, serverInfo: { pollInterval: number }): void {
  if (!morphSrvCheckSpaceExists(srv, space)) {
    srv.spaces[space.id] = {
      space,
      lastPollTime: 0,
      pollInterval: serverInfo.pollInterval
    }
  }
}

// -------------------------------------------------------------------

function morphSrvConvertDbMorphsToSrvMorphs (dbMorphs: DbMorph[]): SrvMorph[] {
  const srvMorphs: SrvMorph[] = []

  for (const dbMorph of dbMorphs) {
    srvMorphs.push({ ...(dbMorph as unknown as SrvMorph), ymut: bytesToBase64(dbMorph.ymut) as YmutBase64 })
  }

  return srvMorphs
}

// -------------------------------------------------------------------

export function morphSrvConvertSrvMorphsToDbMorphs (srvMorphs: SrvMorph[]): DbMorph[] {
  const dbMorphs: DbMorph[] = []

  for (const srvMorph of srvMorphs) {
    dbMorphs.push({ ...(srvMorph as unknown as DbMorph), ymut: base64ToBytes(srvMorph.ymut) })
  }

  return dbMorphs
}

// -------------------------------------------------------------------

function morphSrvGetMorphsUrl (spaceId: UuidB62): string {
  return '/morphs/' + spaceId + '/list'
}

// -------------------------------------------------------------------

export async function morphSrvGetSpaceMorphs (
  spaceId: UuidB62,
  instanceClocks0: Record<string, number>
): Promise<SrvMorph[]> {
  const res: MorphSrvGetSpaceMorphsResult = await serverManager.fetchFromServer({
    spaceId,
    method: 'post',
    slug: morphSrvGetMorphsUrl(spaceId),
    data: { instanceClocks: instanceClocks0 }
  })

  return res?.morphs
}

// -------------------------------------------------------------------

export async function morphSrvSendSpaceMorphs (spaceId: UuidB62, dbMorphs: DbMorph[]): Promise<void> {
  const srvMorphs = morphSrvConvertDbMorphsToSrvMorphs(dbMorphs)

  const res = await serverManager.fetchFromServer({
    spaceId,
    method: 'put',
    slug: morphSrvGetMorphsUrl(spaceId),
    data: { morphs: srvMorphs }
  })

  log('morphSrvSendSpaceMorphs POST ', res)
}
