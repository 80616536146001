import { version } from '../../.version'

// -------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const __GIT_TAG_VERSION__: string
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const __GIT_REVISION__: string

// -------------------------------------------------------------------

export function getAppVersion (): string {
  return (
    (version === __GIT_TAG_VERSION__
      ? version
      : version + '/' + __GIT_TAG_VERSION__) +
    '_' +
    __GIT_REVISION__
  )
}

// -------------------------------------------------------------------

function urlIsLocal (): boolean {
  // !/localhost/.test(window.location.hostname) && !/127.0.0.1/.test(window.location.hostname)
  return (
    window?.location?.hostname === 'localhost' ||
    window?.location?.hostname === '127.0.0.1'
  )
}

// -------------------------------------------------------------------

function isDev (): boolean {
  return urlIsLocal() || import.meta.env.DEV
}

// -------------------------------------------------------------------

function isAlpha (): boolean {
  const alphaSubSlug = 'alpha.'
  const slug = window?.location?.hostname?.substring(0, alphaSubSlug.length)
  return !isDev() && slug === alphaSubSlug
}

// -------------------------------------------------------------------

interface Habitat {
  dev: boolean
  alpha: boolean
}

// -------------------------------------------------------------------

export function getHabitat (): Habitat {
  return {
    dev: isDev(),
    alpha: isAlpha()
  }
}
