export function teleport (node: HTMLElement, name = 'teleport-container'): { destroy: () => void } {
  const teleportContainer = document.getElementById(name)
  teleportContainer?.appendChild(node)

  return {
    destroy () {
      node.remove()
    }
  }
}
