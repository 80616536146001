/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { PzApiEmptyReqData, PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import type { PzApiKratosReqData, PzApiKratosResData } from './authBaseApiDefs'
import { pzApiAuthSlug } from './authBaseApiDefs'

// -------------------------------------------------------------------

const pzApiRegistrationSlug = 'registration'

// -------------------------------------------------------------------

export interface PzApiAuthPostRegistrationReqData extends PzApiKratosReqData {
  name: string
  email: string
  password: string
}

// -------------------------------------------------------------------

interface PzApiAuthRegistrationResDataSuccess extends PzApiResDataSuccess {
  identityId: UuidB62
}

// -------------------------------------------------------------------

interface PzApiAuthRegistrationResDataError extends PzApiResDataFail {
  details?: {
    code: string
    field?: string
    message: string
    originalError?: any
  }
}

// -------------------------------------------------------------------

export type PzApiAuthRegistrationResData =
  PzApiAuthRegistrationResDataSuccess |
  PzApiAuthRegistrationResDataError

// -------------------------------------------------------------------

export const pzApiPrepareRegistrationDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiRegistrationSlug}`,
  fetchMethod: 'GET',
  includeContext: true,
  skipAuthCheck: true,
  reqData: {} as PzApiEmptyReqData,
  resData: {} as PzApiKratosResData
} satisfies PzApiRoute<PzApiEmptyReqData, PzApiKratosResData>

// -------------------------------------------------------------------

export const pzApiPostRegistrationDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiRegistrationSlug}`,
  fetchMethod: 'POST',
  includeContext: true,
  skipAuthCheck: true,
  reqData: {} as PzApiAuthPostRegistrationReqData,
  resData: {} as PzApiAuthRegistrationResData
} satisfies PzApiRoute<PzApiAuthPostRegistrationReqData, PzApiAuthRegistrationResData>
