// Constants for localStorage keys
export const HAS_REGISTERED_USER_KEY = 'has_registered_user'
export const ANONYMOUS_USER_KEY = 'anonymous_user'
export const KRATOS_TO_ANONYMOUS_PREFIX = 'kratos_to_anonymous_'

// Auth state constants
export const AUTH_STATE_INITIAL = 'INITIAL'
export const AUTH_STATE_CHECKING_AUTH_STATE = 'CHECKING_AUTH_STATE'
export const AUTH_STATE_NOT_LOGGED_IN = 'NOT_LOGGED_IN'
export const AUTH_STATE_LOGGING_IN = 'LOGGING_IN'
export const AUTH_STATE_REGISTERING = 'REGISTERING'
export const AUTH_STATE_LOGGING_OUT = 'LOGGING_OUT'
export const AUTH_STATE_ERROR = 'ERROR'
export const AUTH_STATE_ANONYMOUS = 'ANONYMOUS'
export const AUTH_STATE_SIGNED_IN = 'SIGNED_IN'
export const AUTH_STATE_SIGNED_OUT = 'SIGNED_OUT'
