<script lang="ts">
import { t } from 'i18next'

import { log } from '@/utils/debug.ts'

import { appState } from '../store/appState.svelte.ts'

import { initiateLogin, initiateSignup, loginWithEmailAndPassword, signup } from '../store/auth.svelte.ts'

import imgUrl from '../../apple-touch-icon.png'
import ZButton from '../components/ZButton.svelte'
import ZInput from '../components/ZInput.svelte'
import { gotoUrl } from '../routes.svelte.ts'

// -------------------------------------------------------------------

const { params } = $props()

// -------------------------------------------------------------------

const isLogin: boolean = $derived(params?.isLogin)
const isRegister: boolean = $derived(params?.isRegister)

// -------------------------------------------------------------------

let email = $state('weg@weg.com')
let password = $state('weg12345!')
let name = $state('weg123')

let errorMsg = $state('')
let loginErrMsg = $state('')

// -------------------------------------------------------------------

const links = {
  login: { href: '/login', preTxt: t('Already a user?'), txt: t('Click here to login.') },
  register: { href: '/register', preTxt: t('No account yet?'), txt: t('Click here to register.') }
}

// -------------------------------------------------------------------
// Call the server to get a csrf_token and flow_id for a new registration.
// -------------------------------------------------------------------

let flowId = $state('')
let csrfToken = $state('')

$effect(() => {
  // eslint-disable-next-line max-statements, complexity
  ;(async () => {
    // Allow anonymous users to access the register page
    if (appState.isLoggedInAndIdKnown === true && !appState.isAnonymousUser &&
        (window.location.pathname === '/login' || window.location.pathname === '/register')) {
      gotoUrl('/')
    } else {
      if (isLogin) {
        const res = await initiateLogin()

        if (res.ok && res.flowId != null && res.csrfToken != null && res.flowId !== '' && res.csrfToken !== '') {
          flowId = res.flowId
          csrfToken = res.csrfToken
        }

        if (!res.ok && res.errMsg !== undefined && res.errMsg !== '') {
          errorMsg = res.errMsg
        }
      }

      if (isRegister) {
        const res = await initiateSignup()

        if (typeof res?.errorMsg === 'string') {
          errorMsg = res.errorMsg
        }

        if (res.flowId != null && res.csrfToken != null && res.flowId !== '' && res.csrfToken !== '') {
          flowId = res.flowId
          csrfToken = res.csrfToken
        }
      }
    }

    // TODO Use the ui.nodes data to get and render the form fields, instead of hardcode them?
    // TODO Get the messages from the response and show them in the UI.
    // TODO Somehow handle the case when the flowId and csrf_token are not (yet) set.
  })().catch((error) => log(0, 'Error in initiate Login/Signup:', error))
})

// -------------------------------------------------------------------

function resetErrors (): void {
  errorMsg = ''
  loginErrMsg = ''
}

// -------------------------------------------------------------------

async function submitLogin (): Promise<void> {
  if ((await loginWithEmailAndPassword({ email, password, flowId, csrfToken })).ok === true) {
    // log('submitLogin Login successful')
    gotoUrl('/')
  }
}

// -------------------------------------------------------------------

async function submitRegister (): Promise<void> {
  try {
    const res = await signup({ email, password, name: name ?? email, flowId, csrfToken })

    if (typeof res?.errorMsg === 'string') {
      errorMsg = res.errorMsg
    }

    if (typeof res?.loginErrMsg === 'string') {
      loginErrMsg = res.loginErrMsg
    }

    if (typeof res?.errorMsg !== 'string') {
      // Registration was successful
      gotoUrl('/')
    }
  } catch (error) {
    log(0, 'Error during registration', String(error), error)
    errorMsg = String(error) || 'An unexpected error occurred during registration. Please try again.'
  }
}

// -------------------------------------------------------------------

async function submit (event: Event): Promise<void> {
  event.preventDefault()

  resetErrors()

  if (isLogin) {
    await submitLogin()
  }

  if (isRegister) {
    await submitRegister()
  }
}
</script>

<!-- ------------------------------------------------------------- -->

{#snippet lnk({ href, txt, preTxt })}
  {preTxt}
  <a class="decoration-pz_gray-light hover:decoration-pz_gray-mid underline underline-offset-[3px]" {href}>
    {txt}
  </a>
{/snippet}

<!-- ------------------------------------------------------------- -->

<!-- <p v-if="needsEmailVerification">
  Your email is not yet verified. Please check your mailbox and
  follow the verification link to finish registration.
</p> -->

<!-- v-else -->

<div class="bg-primary flex w-full grow items-center justify-center">
  <div class="mr-2 max-w-[30vw] break-all text-xs">
    isLogin: {isLogin}<br />
    isRegister: {isRegister}<br />
    isCheckingForLogin: {appState.isCheckingForLogin}<br />
    isReadyForAction: {appState.isReadyForAction}<br />
    isLoggedInAndIdKnown: {appState.isLoggedInAndIdKnown}<br />
    isInstanceKnown: {appState.isInstanceKnown}<br />
    isSpacesKnown: {appState.isSpacesKnown}<br />
    <!-- activeSpace?.db: {appState.activeSpace?.db}<br /> -->
    <!-- instance?.db: { appState.instance?.db }<br /> -->
    <!-- userDb: {JSON.stringify(appState.userDb)}<br /> -->
    authState: {appState.authState}<br />
    authStateError: {appState.authStateError}<br />
    authenticatedUser: {JSON.stringify(appState.authenticatedUser)}<br />
    authenticatedUser?.id: {appState.authenticatedUser?.id}<br />
  </div>

  <form class=" rounded-3xl bg-white p-8" onsubmit={submit}>
    <!-- <div class="relative mb-10 flex w-full flex-col items-center">
      <div class="absolute top-[-74px] overflow-hidden rounded-3xl border-[4px] border-primary bg-white p-2">
        <img class="h-16 w-16 translate-x-[2px]" alt="logo" src={ imgUrl } />
      </div>
    </div> -->

    <div class=" mb-10 flex w-full flex-col items-center">
      <img class="h-16 w-16 translate-x-[2px]" alt="logo" src={imgUrl} />
    </div>

    {#if errorMsg}
      <div class="text-error">
        {errorMsg}
        {#if loginErrMsg}
          <a href="/login">
            {loginErrMsg}
          </a>
        {/if}
      </div>
    {/if}

    <ZInput
      class="input mb-2"
      allowPasswordManagerIcons={true}
      placeholder={t('Email')}
      type="email"
      bind:value={email}
    />
    {#if isRegister}
      <ZInput class="input mb-2" allowPasswordManagerIcons={true} placeholder={t('Name')} bind:value={name} />
    {/if}
    <ZInput
      class="input mb-2"
      allowPasswordManagerIcons={true}
      placeholder={t('Password')}
      type="password"
      bind:value={password}
    />

    <ZButton class="btn-submit mt-2 w-full" type="submit">
      {isLogin ? t('Login') : t('Register')}
    </ZButton>

    <br /><br />

    {#if isRegister}
      {@render lnk(links.login)}
    {/if}

    {#if isLogin}
      {@render lnk(links.register)}
    {/if}
  </form>
</div>
