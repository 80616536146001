<script lang="ts">
import { hideNotification } from '../store/notifications.svelte.ts'

import { cn } from '../utils/shadcnUtils.ts'

import NotificationHideMenu from './NotificationHideMenu.svelte'

import ZIcon from './ZIcon.svelte'
import NuIconClose from './icons/NuIconClose.svelte'

// -------------------------------------------------------------------

type NotificationType = 'info' | 'warning' | 'error'

// -------------------------------------------------------------------

const {
  title,
  type = 'info' as NotificationType,
  icon,
  onClose,
  onClick,
  links = {},
  allowHide = false, // New prop
  id // New prop
} = $props()

// -------------------------------------------------------------------

let showHideMenu = $state(false)

// -------------------------------------------------------------------

function handleClose (e: Event): void {
  e.stopPropagation()
  if (allowHide) {
    showHideMenu = true
  } else if (onClose) {
    onClose()
  }
}

// -------------------------------------------------------------------

const styles: Record<NotificationType, string> = {
  info: 'bg-blue-50 text-blue-800 !border-blue-500 border-solid border-[1px]',
  warning: 'bg-yellow-50 text-yellow-800 !border-yellow-500 border-solid border-[1px]',
  error: 'bg-red-50 text-red-800 !border-red-500 border-solid border-[1px]'
}

// -------------------------------------------------------------------

type ParsedLink = {
  text: string
  onClick: (_e: Event) => void
}

// -------------------------------------------------------------------

function parseLinks (text: string): (string | ParsedLink)[] {
  const regex = /(\[([^\]]+)\]\(([^)]+)\))/g
  const result: (string | ParsedLink)[] = []

  const processMatch = (match: RegExpMatchArray): ParsedLink => {
    const [, , linkText, linkKey] = match
    const handler = links[linkKey]

    return {
      text: linkText,
      onClick: handler ? (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
        handler()
      } : () => console.log('No handler for:', linkKey)
    }
  }

  let lastIndex = 0
  const matches = [...text.matchAll(regex)]

  matches.forEach(match => {
    const currentIndex = match.index!
    if (currentIndex > lastIndex) {
      result.push(text.substring(lastIndex, currentIndex))
    }
    result.push(processMatch(match))
    lastIndex = currentIndex + match[0].length
  })

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex))
  }

  return result
}

// -------------------------------------------------------------------

const parsedTitle = parseLinks(title)
</script>

<!-- ------------------------------------------------------------- -->

<div
  style="border-style: solid;"
  class={cn(
    'flex w-fit min-w-[400px] max-w-[calc(100vw-24px)] items-center justify-between rounded-2xl px-4 py-1 text-sm shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)]',
    styles[type],
    onClick && 'cursor-pointer'
  )}
  onclick={onClick}
  onkeydown={(e) => {
    if (onClick && e.key === 'Enter') {
      e.preventDefault()
      onClick(e)
    }
  }}
  role="button"
  tabindex="0"
>
  <div class="flex grow items-center">
    {#if icon}
      <ZIcon class="mr-2 size-4" {icon} onclick={() => {}} />
    {/if}
    {#each parsedTitle as part, i (i)}
      {#if typeof part === 'string'}
        <!-- Add white-space: pre to preserve spaces -->
        <span style="white-space: pre">{part}</span>
      {:else}
        <button
          class="font-medium hover:underline"
          onclick={part.onClick}
        >
          {part.text}
        </button>
      {/if}
    {/each}
  </div>

  {#if onClose || allowHide}
    <button
      class="rounded-l2 -mr-1.5 ml-2 rounded-lg bg-current p-1 [&_.z-icon]:size-2 [&_.z-icon]:text-white"
      onclick={handleClose}
    >
      <ZIcon icon={NuIconClose} onclick={() => {}} />
    </button>
  {/if}
</div>

{#if showHideMenu}
  <NotificationHideMenu
    {id}
    onHide={(days) => {
      if (days > 0) {
        hideNotification(id, days)
      }
      showHideMenu = false
    }}
    {title}
  />
{/if}
