/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { pzApiSpace } from '../../spaces'
import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { pzApiSpacesSlug } from './spacesBaseApiDefs'

// -------------------------------------------------------------------
// External request type (used by the app)
// -------------------------------------------------------------------

export interface PzApiSpacesInsertReqData {
  spaceId: UuidB62
  name: string
}

// -------------------------------------------------------------------
// Internal request type (used within the API)
// -------------------------------------------------------------------

export interface PzApiSpacesInsertReqDataInternal extends PzApiSpacesInsertReqData {
  authenticatedUserId: UuidB62
}

// -------------------------------------------------------------------

export type PzApiSpacesInsertResData =
  | (PzApiResDataSuccess & { spaces: pzApiSpace[] })
  | (PzApiResDataFail & { spaces: pzApiSpace[] })

// -------------------------------------------------------------------

export const pzApiSpacesInsertDef = {
  fetchUrl: `/${pzApiSpacesSlug}/insert`,
  fetchMethod: 'PUT',
  reqData: {} as PzApiSpacesInsertReqDataInternal,
  resData: {} as PzApiSpacesInsertResData
} satisfies PzApiRoute<PzApiSpacesInsertReqDataInternal, PzApiSpacesInsertResData>
