<script lang="ts">
import AppSideMenu from './AppSideMenu.svelte'
import AppSideMenuList from './AppSideMenuList.svelte'
import AppSideMenuListItem from './AppSideMenuListItem.svelte'

import NuIconSnooze from './icons/NuIconSnooze.svelte'

// -------------------------------------------------------------------

const {
  title,
  onHide
}: {
  id: string
  title: string
  onHide: (_days: number) => void
} = $props()

// -------------------------------------------------------------------

let sideMenuComponent: AppSideMenu

// -------------------------------------------------------------------

const hideOptions = [
  { label: 'Hide for a day', days: 1, icon: NuIconSnooze },
  { label: 'Hide for a week', days: 7, icon: NuIconSnooze },
  { label: 'Hide for a month', days: 30, icon: NuIconSnooze }
]

// -------------------------------------------------------------------

function handleHide (days: number): void {
  onHide(days)
  sideMenuComponent.initClose()
}

// -------------------------------------------------------------------

function stripMarkdownLinks (text: string): string {
  return text.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
}

const plainTitle = stripMarkdownLinks(title)
</script>

<!-- ------------------------------------------------------------- -->

<AppSideMenu
  bind:this={sideMenuComponent}
  onClose={() => onHide(0)}
  title="Hide Notification"
>
  <div class="relative px-8 pb-4 text-sm text-gray-600">
    <span class="absolute -left-1 -top-2 font-serif text-6xl text-gray-200">&ldquo;</span>
    {plainTitle}
    <span class="absolute -bottom-6 -right-1 font-serif text-6xl text-gray-200">&rdquo;</span>
  </div>
  <AppSideMenuList>
    {#each hideOptions as option (option.days)}
      <AppSideMenuListItem
        icon={option.icon}
        onclick={() => handleHide(option.days)}
        title={option.label}
      />
    {/each}
  </AppSideMenuList>
</AppSideMenu>
