declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    pzDebugLogs: string[]
    pzInitialDebugLog: string | undefined
    // eslint-disable-next-line no-unused-vars
    updatePlaywrightTestName: (name: string) => void
    pzGetAndEmptyDebugLogs: () => string[]
  }
}

// -------------------------------------------------------------------

const MAX_LOG_SEVERITY = 4 // 3

let lastMsg = ''
let lastCnt = 0

// -------------------------------------------------------------------

function updateRepeatCounter (lastMsgNode: HTMLElement): void {
  const cntNodes = lastMsgNode.querySelectorAll('.pz-dbg-cnt-node')
  if (cntNodes.length > 0) {
    const cntNode = cntNodes[cntNodes.length - 1] as HTMLElement
    cntNode.innerHTML = lastCnt.toString()
  } else {
    lastMsgNode.innerHTML += '<span class="pz-dbg-cnt-node">' + lastCnt + '</span>'
  }
}

// -------------------------------------------------------------------

function limitLinesOfDebugPane (debugPane: HTMLElement): void {
  if (debugPane.childNodes.length > 100) {
    debugPane.removeChild(debugPane.childNodes[0])
  }
  debugPane.scrollTop = debugPane.scrollHeight
}

// -------------------------------------------------------------------

function updateDebugPane (s: string): void {
  const debugPane = document.getElementById('pz-debug-pane')
  if (debugPane == null) return

  if (lastMsg !== s) {
    debugPane.innerHTML += '<div>' + s + '</div>'
    lastCnt = 0
  } else {
    lastCnt++
    const lastMsgNode = debugPane.childNodes[debugPane.childNodes.length - 1] as HTMLElement
    updateRepeatCounter(lastMsgNode)
  }

  limitLinesOfDebugPane(debugPane)
}

// -------------------------------------------------------------------

function getTruncatedArgs (msgs: any[]): string {
  return msgs
    .map(msg => (typeof msg === 'string' ? msg : JSON.stringify(msg)))
    .join(' ')
}

// -------------------------------------------------------------------

function getTruncatedMsg (s: string): string {
  // Truncate string, adding '...' if necessary.
  const maxLen = 80
  const dots = '…'
  const truncated =
        s.length > maxLen - dots.length ? s.substring(0, maxLen - dots.length) + dots : s
  return truncated
}

// -------------------------------------------------------------------

function getSeverityAddPrefixGenOutput (msgs: any[]): any[] {
  let severity: number = 1

  if (typeof msgs[0] === 'number') {
    severity = msgs[0]
    msgs = msgs.slice(1)
  }

  if (typeof msgs[0] === 'string') {
    // ► ▷ ◯ ⮕ ✖ ❗ ⚠ ◦ ∙
    const icon = '❗●◯∙◦'.charAt(severity)
    msgs[0] = icon + ' ' + msgs[0]
  }

  return [msgs, severity]
}

// -------------------------------------------------------------------

function addToPzDebugLogs (s: string): void {
  if (lastMsg !== s) {
    const secondsSinceStart = ((Date.now() - playwrightTestStartTm) / 1000).toFixed(3)
    window.pzDebugLogs.push(secondsSinceStart + ' ' + getTruncatedMsg(s))
    lastMsg = s
  }
}

// -------------------------------------------------------------------

export function log (...msgs: any[]): void {
  let severity: number
  [msgs, severity] = getSeverityAddPrefixGenOutput(msgs)

  const s = getTruncatedArgs(msgs)

  updateDebugPane(s)

  addToPzDebugLogs(s)

  if (severity <= MAX_LOG_SEVERITY) {
    console.groupCollapsed(...msgs)
    console.trace('')
    console.groupEnd()
  }
}

// -------------------------------------------------------------------

window.pzInitialDebugLog = window.pzInitialDebugLog || undefined
window.pzDebugLogs = []

let lastLoggedPlaywrightTestName = ''
let playwrightTestStartTm = 0

// -------------------------------------------------------------------

window.updatePlaywrightTestName = (name: string): void => {
  updatePzDebugLogs(name)
}

// -------------------------------------------------------------------

window.pzGetAndEmptyDebugLogs = () => {
  const ret = window.pzDebugLogs
  window.pzDebugLogs = []
  return ret
}

// -------------------------------------------------------------------

function updatePzDebugLogs (name: string | undefined): void {
  if (playwrightTestStartTm === 0) {
    playwrightTestStartTm = Date.now()
  }

  if (name !== undefined && lastLoggedPlaywrightTestName !== name) {
    const c = name,
      w = Math.max(c.length + 2, 70 - 2),
      pad = w - c.length,
      l = Math.floor(pad / 2),
      r = pad - l
    window.pzDebugLogs.push(`\n╔${'═'.repeat(w)}╗\n║${' '.repeat(l)}${c}${' '.repeat(r)}║\n╚${'═'.repeat(w)}╝\n`)

    lastLoggedPlaywrightTestName = name
  }
}

// -------------------------------------------------------------------

updatePzDebugLogs(window.pzInitialDebugLog)

// log(0, 'aaaaaaaaa')
// setInterval(() => {
//   log(0, 'bbb')
// }, 100)
