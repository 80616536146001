import {
  AUTH_STATE_ANONYMOUS,
  AUTH_STATE_NOT_LOGGED_IN,
  AUTH_STATE_SIGNED_IN,
  AUTH_STATE_SIGNED_OUT
} from '@/constants/appStateConstants.ts'
import { startEntityPolling } from '@/services/entitySync.ts'

import { type AppState, injectState, type UseStateReturn } from './appState.svelte.ts'

// -------------------------------------------------------------------

export interface ReadyForActionState {
  isReadyForAction: boolean
  isCheckingForLogin: boolean
}

// -------------------------------------------------------------------

let hasStartedPolling = false

// -------------------------------------------------------------------

export const useReadyState = (): UseStateReturn => {
  return {
    extendState (aState: AppState): ReturnType<UseStateReturn['extendState']> {
      injectState(
        'isReadyForAction',
        () => {
          const isReady = aState.isLoggedInAndIdKnown === true &&
            aState.isInstanceKnown === true &&
            aState.isSpacesKnown === true &&
            aState.isTribesKnown === true &&
            aState.userDb !== undefined

          if (isReady && !hasStartedPolling) {
            // log('!!!useReadyState: isReadyForAction is true, starting entity polling')
            startEntityPolling()
            hasStartedPolling = true
          }

          return isReady
        }
      )

      injectState(
        'isCheckingForLogin',
        () =>
          aState.authState !== AUTH_STATE_ANONYMOUS &&
          aState.authState !== AUTH_STATE_NOT_LOGGED_IN &&
          aState.authState !== AUTH_STATE_SIGNED_IN &&
          aState.authState !== AUTH_STATE_SIGNED_OUT
      )
    },

    start: (): ReturnType<UseStateReturn['start']> => {}
  }
}
