import { type AppState, appState, injectState, type UseStateReturn } from './appState.svelte.ts'

import {
  getMorphSrvForSpace,
  morphSrvCheckAddSpace,
  type MorphSrvClient,
  morphSrvCreateClient } from '../services/morphSrv.ts'
import { startMorphPolling } from '../services/morphSync.ts'

import { log } from '../utils/debug.ts'

import type { Space } from './space.svelte.ts'

// -------------------------------------------------------------------

const morphSrvClients: Record<string, MorphSrvClient> = $state({})

// -------------------------------------------------------------------

export interface MorphSrvClientsState {
  morphSrvClients: Record<string, MorphSrvClient>
}

// -------------------------------------------------------------------

function checkAddMorphSrvClient (space: Space): void {
  if (appState.userDb !== undefined && appState.morphSrvClients !== undefined) {
    const morphSrvDef = getMorphSrvForSpace(space.id)
    log(2, 'checkAddMorphSrvClient 111', morphSrvDef)

    if (!appState.morphSrvClients[space.id]) {
      appState.morphSrvClients[space.id] = morphSrvCreateClient(morphSrvDef)
      startMorphPolling(appState.morphSrvClients[space.id], appState.userDb.db)
    }

    morphSrvCheckAddSpace(appState.morphSrvClients[space.id], space, morphSrvDef)
  }
}

// -------------------------------------------------------------------

export function useMorphSrvClients (): UseStateReturn {
  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      $effect.root(() => {
        $effect(() => {
          // Do not use appState.spaces directly, as that will not be reactive.
          // Instead, use the destructured spaces variable, which will be reactive!
          const { isLoggedInAndIdKnown, userDb, spaces } = appState

          // console.log('useMorphSrvClients 000', isLoggedInAndIdKnown, userDb, spaces)

          if (
            isLoggedInAndIdKnown === true &&
            userDb !== undefined &&
            spaces !== undefined
          ) {
            for (const space of Object.values(spaces)) {
              checkAddMorphSrvClient(space)
            }
          }
        })
      })
    },

    extendState (): ReturnType<UseStateReturn['extendState']> {
      injectState('morphSrvClients', () => morphSrvClients)
    }
  }
}
