<script lang="ts">
import type { Component } from 'svelte'

import { cn } from '../utils/shadcnUtils.ts'

import ZIcon from './ZIcon.svelte'

// -------------------------------------------------------------------

const {
  icon,
  title,
  divider = false,
  onclick,
  children,
  ...props
}: { icon?: Component, title?: string, divider?: boolean, onclick: () => void } = $props()

const className: string = typeof props.class === 'string' ? props.class : ''
</script>

<!-- ------------------------------------------------------------- -->

<div
  class={cn(
    'a-app-side-menu-list-item cursor-pointer rounded-full px-4 py-1.5 hover:bg-primary-light [&_.z-icon]:mr-2 hover:[&_.z-icon]:text-inherit',
    divider && 'a-divider radius-0 mx-5 my-7 cursor-default border-t border-solid border-pz_gray-light p-0!',
    className
  )}
  {onclick}
  onkeydown={(e) => {
    e.preventDefault()
    e.key === 'Enter' && onclick()
  }}
  role="button"
  tabindex="0"
>
  {#if children}
    {@render children()}
  {:else}
    <div class="flex flex-row items-center">
      {#if icon}
        <ZIcon {icon} />
      {/if}

      {#if title}
        <div class="a-title truncate">
          {title}
        </div>
      {/if}
    </div>
  {/if}
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.a-app-side-menu-list-item
  font-family var(--z-font-body)
  font-weight 700
  font-size 18px
</style>
