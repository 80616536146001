import type { PzApiResDataFail, PzApiResDataSuccess } from '../apiDefs'

// -------------------------------------------------------------------

export const pzApiAuthSlug = 'auth'

// -------------------------------------------------------------------

export interface PzApiKratosResDataSuccess extends PzApiResDataSuccess {
  flowId: string
  csrfToken: string
}

// -------------------------------------------------------------------

export type PzApiKratosResData = PzApiKratosResDataSuccess | PzApiResDataFail

// -------------------------------------------------------------------

export interface PzApiKratosReqData {
  flowId: string
  csrfToken: string
}
