/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { pzApiSpace } from '../../spaces'
import type { PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiSpacesSlug } from './spacesBaseApiDefs'

// -------------------------------------------------------------------

export interface PzApiSpacesListReqData extends PzApiBaseReqData {
  userId?: string
  includeUsers?: boolean
}

// -------------------------------------------------------------------

export type PzApiSpacesListResData =
  | (PzApiResDataSuccess & { spaces: pzApiSpace[] })
  | PzApiResDataFail

// -------------------------------------------------------------------

export const pzApiSpacesListDef = {
  fetchUrl: `/${pzApiSpacesSlug}/list`,
  fetchMethod: 'POST',
  reqData: {} as PzApiSpacesListReqData,
  resData: {} as PzApiSpacesListResData
} satisfies PzApiRoute<PzApiSpacesListReqData, PzApiSpacesListResData>
