import {
  type DB,
  type DbMorph,
  dexieAddMorph,
  dexieGetAllMorphsByBreed,
  dexieGetMorphsByItemId,
  dexieInitDb
} from '@/utils/dexie.ts'
import type { UuidB62 } from '@/utils/id.ts'
import type { YDoc } from '@/utils/y.ts'
import { yDocApplySubDocUpdate, yDocApplyUpdate } from '@/utils/y.ts'

import type { Instance } from '../store/instance.svelte.ts'

// -------------------------------------------------------------------

export interface YDB {
  db: DB
  clock: number // ATTENTION: Another tab in the same browser can have a higher clock, while being the same instance.
}

// -------------------------------------------------------------------

export function ydbInitDB (instanceId: UuidB62, instance: Instance): YDB {
  const dbName = 'ydb_' + instanceId

  const db = dexieInitDb(dbName, instance)

  // log('ydbInitDB', dbName)

  return {
    db,
    clock: -1
  }
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-lines-per-function
export async function ydbMorph (db: YDB, dat: DbMorph): Promise<void> {
  // console.log('ydbMorph 000', db, dat)
  try {
    /* const res = */ await dexieAddMorph(db.db, dat)
    // console.log('ydbMorph 111', res)
  } catch (err) {
    console.error('ydbMorph ERR', err)
  }
}

// -------------------------------------------------------------------

export async function ydbGetOneByItemIdAndApplyMorphs ({
  db,
  itemId,
  doc,
  subscribe = false
}: {
  db: YDB
  itemId: UuidB62
  doc: YDoc
  subscribe?: boolean
}): Promise<void> {
  function applyData (res: DbMorph[]): void {
    for (const row of res) {
      // console.log('ydbGetOneByItemIdAndApplyMorphs 111', row.ky, row)
      if (row.ky?.startsWith('#')) {
        yDocApplySubDocUpdate({ doc, update: row.ymut, origin: 'applyfromdb', ky: row.ky })
      } else {
        yDocApplyUpdate(doc, row.ymut, 'applyfromdb')
      }
      // TODO This will re-apply all morphs, even if they have already been applied. Because the Dexie liveQuery will re-trigger the callback for all morphs.
    }
  }

  const res = await dexieGetMorphsByItemId(db.db, itemId, subscribe ? applyData : undefined)
  applyData(res)

  // console.log('ydbGetOneByItemIdAndApplyMorphs 222', res)
}

// -------------------------------------------------------------------

function getYdocByItemIdFromArray (itemId: UuidB62, docs: YDoc[]): YDoc | undefined {
  for (const doc of docs) {
    if (doc.id === itemId) {
      return doc
    }
  }
  return undefined
}

// -------------------------------------------------------------------

interface ydbGetAllByBreedAndApplyMorphsParams {
  db: YDB
  breed: string
  docs: YDoc[]
  callbackItemCreate: (itemId: UuidB62) => YDoc
  subscribe?: boolean
}

export async function ydbGetAllByBreedAndApplyMorphs ({
  db,
  breed,
  docs,
  callbackItemCreate,
  subscribe = false
}: ydbGetAllByBreedAndApplyMorphsParams): Promise<void> {
  function applyData (res: DbMorph[]): void {
    // console.log('ydbGetAllByBreedAndApplyMorphs 000', res)
    for (const row of res) {
      // console.log('ydbGetAllByBreedAndApplyMorphs 111', row)

      let doc = getYdocByItemIdFromArray(row.itemId as UuidB62, docs)
      if (doc === undefined) {
        // console.log('ydbGetAllByBreedAndApplyMorphs 222', row)
        doc = callbackItemCreate(row.itemId as UuidB62)

        docs.push(doc)
      }

      // console.log('ydbGetAllByBreedAndApplyMorphs 444', doc, row.ymut)
      yDocApplyUpdate(doc, row.ymut, 'applyfromdb')
    }
  }

  const res = await dexieGetAllMorphsByBreed(db.db, breed, subscribe ? applyData : undefined)
  applyData(res)

  // console.log('ydbGetAllByBreedAndApplyMorphs 333', docs, docs.length)
}
