import type { MandeInstance, OptionsRaw, ResponseAsTypes } from 'mande'
import { mande } from 'mande'

import type { UuidB62 } from '@/utils/id.ts'

import { getHabitat } from '../store/env.ts'

// -------------------------------------------------------------------

export interface ServerHealth {
  isHealthy: boolean
  lastChecked: number
  responseTime: number
  error?: string
}

// -------------------------------------------------------------------

interface BaseServerDefinition {
  url: string
  priority: number
  isActive: boolean
  lastChecked: number
  responseTime?: number
  health: ServerHealth
}

// -------------------------------------------------------------------

interface ServerType {
  isHub: boolean
  isSync: boolean
}

// -------------------------------------------------------------------

interface Server extends BaseServerDefinition {
  type: ServerType
  region: string
  spaces: string[]
  currentLoad: number
  pollInterval: number
}

// -------------------------------------------------------------------

interface ServerWithId extends Server {
  serverId: string
}

// -------------------------------------------------------------------

interface ServerState {
  activeHubUrl: string | null
  serverHealth: Record<string, ServerHealth>
  spaceAssignments: Record<string, Server>
}

// -------------------------------------------------------------------

const serverState = $state<ServerState>({
  activeHubUrl: null,
  serverHealth: {},
  spaceAssignments: {}
})

// -------------------------------------------------------------------

interface FetchParams {
  serverId?: string
  spaceId?: UuidB62
  hubServer?: boolean // New parameter
  method: string
  slug: string
  data?: unknown
  options?: RequestInit
  withAuth?: boolean // New parameter to indicate if auth options should be included
}

// -------------------------------------------------------------------

export class ServerManager {
  private static instance: ServerManager
  private servers: Record<string, Server> = {}

  private healthCheckInterval?: number
  private readonly HEALTH_CHECK_INTERVAL = 30_000 // 30 seconds
  private readonly DEFAULT_POLL_INTERVAL = 10_000 // 10 seconds for morph polling
  private readonly MAX_RETRY_ATTEMPTS = 3
  private readonly TIMEOUT = 5000 // 5 seconds
  private isInitialized = false

  // -------------------------------------------------------------------

  private readonly authOptions: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // -------------------------------------------------------------------

  private constructor () {
    this.initializeServers()
    void this.checkAllServersHealth() // Immediate first health check
    this.startHealthChecks()
  }

  // -------------------------------------------------------------------

  static getInstance (): ServerManager {
    if (!ServerManager.instance) {
      ServerManager.instance = new ServerManager()
    }
    return ServerManager.instance
  }

  // -------------------------------------------------------------------

  private initializeServers (): void {
    const habitat = getHabitat()

    if (habitat.dev) {
      this.servers['DEV'] = {
        url: 'http://127.0.0.1:3000',
        type: { isHub: true, isSync: true },
        priority: 1,
        isActive: true,
        lastChecked: 0,
        health: { isHealthy: false, lastChecked: 0, responseTime: 0 },
        region: 'default',
        spaces: [],
        currentLoad: 0,
        pollInterval: 1_000
      }
    } else {
      this.servers['SRV0'] = {
        url: 'https://srv0.peanutz.com',
        type: { isHub: true, isSync: true },
        priority: 1,
        isActive: true,
        lastChecked: 0,
        health: { isHealthy: false, lastChecked: 0, responseTime: 0 },
        region: 'default',
        spaces: [],
        currentLoad: 0,
        pollInterval: this.DEFAULT_POLL_INTERVAL
      }
    }
  }

  // -------------------------------------------------------------------

  private addServer (config: {
    type: ServerType
    url: string
    priority: number
    region?: string
  }): void {
    this.servers[config.url] = {
      url: config.url,
      type: config.type,
      priority: config.priority,
      isActive: true,
      lastChecked: 0,
      health: {
        isHealthy: true,
        lastChecked: 0,
        responseTime: 0
      },
      region: config.region || 'unknown',
      spaces: [],
      currentLoad: 0,
      pollInterval: this.DEFAULT_POLL_INTERVAL
    }
  }

  // -------------------------------------------------------------------

  private startHealthChecks (): void {
    this.healthCheckInterval = window.setInterval(
      () => this.checkAllServersHealth(),
      this.HEALTH_CHECK_INTERVAL
    )
  }

  // -------------------------------------------------------------------

  private async checkAllServersHealth (): Promise<void> {
    const serverEntries = Object.entries(this.servers)

    for (const [serverId, server] of serverEntries) {
      try {
        const health = await this.checkServerHealth(serverId)
        this.updateServerHealth(server.url, health)
      } catch (error) {
        this.updateServerHealth(server.url, {
          isHealthy: false,
          lastChecked: Date.now(),
          responseTime: 0,
          error: error instanceof Error ? error.message : String(error)
        })
      }
    }

    this.updateActiveHubServer()
  }

  // -------------------------------------------------------------------

  private async checkServerHealth (serverId: string): Promise<ServerHealth> {
    if (getHabitat().dev) {
      return {
        isHealthy: true,
        lastChecked: Date.now(),
        responseTime: 0
      }
    }

    const startTime = performance.now()
    const response = await this.fetchHealthStatus(serverId)
    const responseTime = performance.now() - startTime

    await this.handlePollInterval(serverId, response)

    return {
      isHealthy: true,
      lastChecked: Date.now(),
      responseTime
    }
  }

  // -------------------------------------------------------------------

  private async fetchHealthStatus (serverId: string): Promise<Response> {
    try {
      const response = await this.fetchFromServer({
        serverId,
        method: 'get',
        slug: '/health',
        options: {
          signal: AbortSignal.timeout(this.TIMEOUT)
        }
      })

      return response as Response
    } catch (error) {
      throw new Error(`Health check failed: ${error instanceof Error ? error.message : 'Unknown error'}`)
    }
  }

  // -------------------------------------------------------------------

  private async handlePollInterval (serverId: string, response: Response): Promise<void> {
    const contentType = response.headers.get('content-type')
    if (!contentType?.includes('application/json')) return

    try {
      const data = await response.json()
      if (data?.pollInterval && typeof data.pollInterval === 'number') {
        this.setPollInterval(serverId, data.pollInterval)
      }
    } catch {
      // Ignore JSON parsing errors, keep default poll interval
    }
  }

  // -------------------------------------------------------------------

  private updateServerHealth (url: string, health: ServerHealth): void {
    serverState.serverHealth[url] = health
  }

  // -------------------------------------------------------------------

  private updateActiveHubServer (): void {
    const healthyHubs = Object.values(this.servers)
      .filter(server => server.type.isHub && serverState.serverHealth[server.url]?.isHealthy)
      .sort((a, b) => {
        if (a.priority !== b.priority) return a.priority - b.priority
        return (serverState.serverHealth[a.url]?.responseTime || 0) -
               (serverState.serverHealth[b.url]?.responseTime || 0)
      })

    serverState.activeHubUrl = healthyHubs[0]?.url || null
  }

  // -------------------------------------------------------------------

  private handleDevServer (type: ServerType, spaceId?: UuidB62): { serverId: string, server: Server } | null {
    const habitat = getHabitat()
    if (habitat.dev && this.servers['DEV']) {
      if (spaceId) {
        this.assignSpaceToServer(this.servers['DEV'], spaceId)
      }
      return { serverId: 'DEV', server: this.servers['DEV'] }
    }
    return null
  }

  // -------------------------------------------------------------------

  private filterAndSortServers (type: ServerType, requireHealthy?: boolean): [string, Server][] {
    return Object.entries(this.servers)
      .filter(([_id, server]) => {
        const typeMatch = server.type.isHub === type.isHub && server.type.isSync === type.isSync
        const healthMatch = !requireHealthy ||
          (serverState.serverHealth[server.url]?.isHealthy ?? true)
        return typeMatch && healthMatch
      })
      .sort(([, a], [, b]) => {
        if (a.priority !== b.priority) return a.priority - b.priority
        if (type.isSync) return a.currentLoad - b.currentLoad
        return (serverState.serverHealth[a.url]?.responseTime || 0) -
               (serverState.serverHealth[b.url]?.responseTime || 0)
      })
  }

  // -------------------------------------------------------------------

  private getServerByType (type: ServerType, options: {
    spaceId?: UuidB62
    requireHealthy?: boolean
  }): { serverId: string, server: Server } {
    const devServer = this.handleDevServer(type, options.spaceId)
    if (devServer) return devServer

    const sortedServers = this.filterAndSortServers(type, options.requireHealthy)
    if (!sortedServers.length) {
      throw new Error(`No servers available with type: ${JSON.stringify(type)}`)
    }

    const [serverId, server] = sortedServers[0]
    if (options.spaceId) {
      this.assignSpaceToServer(server, options.spaceId)
    }

    return { serverId, server }
  }

  // -------------------------------------------------------------------

  private getHealthyServer (spaceId: UuidB62): string {
    try {
      return this.getServerByType({ isHub: false, isSync: true }, { spaceId, requireHealthy: true }).serverId
    } catch {
      return this.getFallbackServer(spaceId)
    }
  }

  // -------------------------------------------------------------------

  getHubServer (): ServerWithId {
    if (serverState.activeHubUrl) {
      const foundServer = Object.entries(this.servers)
        .find(([_, s]) => s.url === serverState.activeHubUrl && s.type.isHub)

      if (foundServer) {
        const [serverId, server] = foundServer
        return { ...server, serverId }
      }

      const { serverId, server } = this.getServerByType({ isHub: true, isSync: false }, {})
      return { ...server, serverId }
    }

    const { serverId, server } = this.getServerByType({ isHub: true, isSync: false }, {})
    // console.warn('No healthy hub server available, using fallback server:', server.url)
    return { ...server, serverId }
  }

  // -------------------------------------------------------------------

  private assignSpaceToServer (server: Server, spaceId: UuidB62): void {
    if (!server.spaces.includes(spaceId)) {
      server.spaces.push(spaceId)
      server.currentLoad++
    }
    serverState.spaceAssignments[spaceId] = server
  }

  // -------------------------------------------------------------------

  private getFallbackServer (spaceId: UuidB62): string {
    // In dev mode, use the DEV server for everything
    if (getHabitat().dev && this.servers['DEV']) {
      this.assignSpaceToServer(this.servers['DEV'], spaceId)
      return 'DEV'
    }

    // In production, look for any sync server
    const syncServer = Object.entries(this.servers)
      .find(([_, server]) => server.type.isSync)

    if (!syncServer) throw new Error('No sync servers available')

    const [fallbackServerId, fallbackServer] = syncServer
    // console.warn('No healthy sync servers available, using fallback server:', fallbackServer.url)
    this.assignSpaceToServer(fallbackServer, spaceId)

    return fallbackServerId
  }

  // -------------------------------------------------------------------

  getSyncServer (spaceId: UuidB62): ServerWithId {
    const existingServer = serverState.spaceAssignments[spaceId]
    if (existingServer) {
      const serverId = Object.entries(this.servers)
        .find(([_, srv]) => srv.url === existingServer.url)?.[0]
      if (!serverId) throw new Error('Server not found in servers')
      return { ...existingServer, serverId }
    }

    const { serverId, server } = this.getServerByType({ isHub: false, isSync: true }, { spaceId })
    return { ...server, serverId }
  }

  // -------------------------------------------------------------------

  destroy (): void {
    if (this.healthCheckInterval) {
      window.clearInterval(this.healthCheckInterval)
    }
  }

  // -------------------------------------------------------------------

  setPollInterval (serverUrl: string, interval: number): void {
    const server = this.servers[serverUrl]
    if (server) {
      server.pollInterval = interval
    }
  }

  // -------------------------------------------------------------------

  getPollInterval (serverUrl: string): number {
    const server = this.servers[serverUrl]
    return server?.pollInterval ?? this.DEFAULT_POLL_INTERVAL
  }

  // -------------------------------------------------------------------

  private getServerForRequest (params: FetchParams): Server {
    if (params.hubServer) {
      return this.getHubServer()
    }
    if (params.spaceId) {
      return this.getSyncServer(params.spaceId)
    }
    if (params.serverId) {
      const server = this.servers[params.serverId]
      if (!server) {
        throw new Error(`Server not found: ${params.serverId}`)
      }
      return server
    }
    throw new Error('No valid server parameters provided')
  }

  // -------------------------------------------------------------------

  private createRequestOptions (options?: RequestInit, withAuth?: boolean): OptionsRaw<ResponseAsTypes> {
    if (!withAuth) {
      return options as OptionsRaw<ResponseAsTypes>
    }

    const headers = {
      ...this.authOptions.headers,
      ...(options?.headers ? Object.fromEntries(new Headers(options.headers)) : {})
    }

    return {
      ...this.authOptions,
      ...options,
      headers
    } as OptionsRaw<ResponseAsTypes>
  }

  // -------------------------------------------------------------------

  public async fetchFromServer<T> ({
    serverId,
    spaceId,
    hubServer,
    method,
    slug,
    data,
    options,
    withAuth
  }: FetchParams): Promise<T> {
    const server = this.getServerForRequest({ serverId, spaceId, hubServer, method, slug })
    const fullUrl = `${server.url}${slug}`
    const requestOptions = this.createRequestOptions(options, withAuth)

    const mandeInstance = mande(fullUrl, {
      ...requestOptions,
      headers: requestOptions?.headers
    })

    const methodName = method.toLowerCase() as keyof MandeInstance
    if (methodName === 'get' && data) {
      // For GET requests with data, we need to pass an empty string as the URL and options as the second parameter
      // We need to ensure headers don't contain null values and properly type the response
      const cleanHeaders = requestOptions?.headers ?
        Object.fromEntries(
          Object.entries(requestOptions.headers)
            .filter(([_, v]) => v !== null)
        ) : undefined

      return await mandeInstance[methodName]('', {
        ...requestOptions,
        headers: cleanHeaders as Record<string, string> | undefined
      }) as T
    }

    const methodFn = mandeInstance[methodName] as (_data?: unknown) => Promise<T>
    return await methodFn(data)
  }
}

// -------------------------------------------------------------------

export const serverManager = ServerManager.getInstance()
