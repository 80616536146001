import type { UserDetails } from '@/apiDefs/users/usersDetailsApiDefs'
import type { KratosUser } from '@/store/auth.svelte.ts'
import { createNewBasicSpace, type Space } from '@/store/space.svelte.ts'
import type { Tribe } from '@/store/tribe.svelte.ts'
import { log } from '@/utils/debug.ts'
import { type UuidB62, uuidV7ToUuidB62 } from '@/utils/id.ts'

import {
  pzApi,
  type PzApiResData,
  type PzApiRoute,
  type PzApiRouteBase
} from '../../../srv/bun/src/apiDefs/apiDefs.ts'
import type { PzApiAuthPostLoginReqData, PzApiAuthPostLogoutData, PzApiAuthPostRegistrationReqData, PzApiAuthPostUserSettingsData, PzApiKratosReqData, PzApiKratosResData } from '../../../srv/bun/src/apiDefs/auth/authApiDefs.ts'
import type { pzApiSpace } from '../../../srv/bun/src/spaces.ts'
import type { pzApiTribe } from '../../../srv/bun/src/tribes.ts'

import { serverManager } from './serverManager.svelte.ts'

// -------------------------------------------------------------------

async function doPzApiRequest<ReqData, ResData> (apiDef: PzApiRouteBase, reqData: ReqData): Promise<ResData> {
  try {
    const res: PzApiResData = await serverManager.fetchFromServer({
      hubServer: true,
      method: (apiDef.fetchMethod ?? 'get'),
      slug: apiDef.fetchUrl,
      data: reqData,
      withAuth: true
    })

    if (!res.ok) {
      throw new Error(res.errMsg ??
        `Request to ${apiDef.fetchUrl} failed: Server returned an error without details`)
    }

    return res as ResData
  } catch (error) {
    throw new Error(`${apiDef.fetchUrl} request failed: ${error instanceof Error ? error.message : String(error)}`)
  }
}

// -------------------------------------------------------------------

async function doPzApiPrepareRequest<ReqData, ResData extends Partial<PzApiKratosResData>> (
  apiDef: PzApiRoute<ReqData, ResData>,
  reqData: ReqData
): Promise<PzApiKratosResData> {
  const response = await doPzApiRequest<typeof apiDef.reqData, typeof apiDef.resData>(apiDef, reqData)

  return getFlowIdAndCsrfToken(response)
}

// -------------------------------------------------------------------

export async function pzApiSrvGetSpaces (userId: UuidB62): Promise<Space[]> {
  const api = pzApi.spaces.list

  const reqData = { userId, authenticatedUserId: userId }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  return convertPzApiSpaceToAppSpace(resData.spaces)
}

// -------------------------------------------------------------------

export async function pzApiSrvCreateSpaceForUser (userId: UuidB62, spaceName?: string): Promise<Space[]> {
  const api = pzApi.spaces.insert
  const space = createNewBasicSpace(userId, spaceName)
  const { id, ...newSpace } = space
  const reqData = { ...newSpace, spaceId: id }

  return await doPzApiRequest(api, reqData)
}

// -------------------------------------------------------------------

function convertPzApiSpaceToAppSpace (data: pzApiSpace[] | null | undefined): Space[] {
  if (!data) {
    return []
  }

  return data.map((item: pzApiSpace) => {
    const spaceId = item.id
    const userId = item.user_id
    return {
      id: spaceId,
      name: item.name,
      users: { [userId]: { id: userId, rights: item.rights } }
    }
  })
}

// -------------------------------------------------------------------

function getFlowIdAndCsrfToken (data: Partial<PzApiKratosResData>): PzApiKratosResData {
  if (data.ok === true) {
    return {
      ok: true as const,
      flowId: data.flowId ?? '',
      csrfToken: data.csrfToken ?? ''
    }
  } else {
    return { ok: false, errMsg: 'getFlowIdAndCsrfToken: data.ok is false' }
  }
}

// -------------------------------------------------------------------

function getDataFromDataOrDataFn<DataOrDataFn> (
  dataOrDataFn: DataOrDataFn,
  prepareData: PzApiKratosReqData
): DataOrDataFn | Record<string, never> {
  let data = dataOrDataFn ?? {}
  if (typeof dataOrDataFn === 'function') {
    data = dataOrDataFn(prepareData)
  }

  return data
}

// -------------------------------------------------------------------

async function doPzApiTwoStepRequest<ReqData, ResData, DataOrDataFn> (
  prepareApi: PzApiRoute<ReqData, ResData>,
  api: PzApiRoute<ReqData, ResData>,
  dataOrDataFn?: DataOrDataFn
): Promise<{ ok: boolean, errMsg?: string }> {
  try {
    const prepareData: PzApiKratosResData = await doPzApiRequest(prepareApi, {})

    if (prepareData.ok) {
      await doPzApiRequest(api, {
        ...getFlowIdAndCsrfToken(prepareData),
        ...getDataFromDataOrDataFn(dataOrDataFn, prepareData)
      })

      return { ok: true }
    }
  } catch (e) {
    const errMsg = e instanceof Error ? e.message : 'Unknown error occurred'
    return { ok: false, errMsg }
  }

  return { ok: false }
}

// -------------------------------------------------------------------

export async function pzApiSrvWhoAmI (): Promise<KratosUser | null> {
  const api = pzApi.auth.whoami

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, {})

  if (resData?.ok && resData?.authenticated && resData?.id !== undefined) {
    return {
      id: uuidV7ToUuidB62(resData.id),
      email: resData.email,
      displayName: resData.name
    }
  } else {
    return null
  }
}

// -------------------------------------------------------------------

export async function pzApiSrvUpdateUserData (
  data: PzApiAuthPostUserSettingsData
): Promise<{ ok: boolean, errMsg?: string }> {
  return await doPzApiTwoStepRequest(pzApi.auth.userSettings.prepare, pzApi.auth.userSettings.post, data)
}

// -------------------------------------------------------------------

export async function pzApiSrvLogout (): Promise<{ ok: boolean, errMsg?: string }> {
  return await doPzApiTwoStepRequest(
    pzApi.auth.logout.prepare,
    pzApi.auth.logout.post,
    (prepareData: PzApiAuthPostLogoutData) => {
      return { logoutToken: prepareData.logoutToken }
    }
  )
}

// -------------------------------------------------------------------

export async function pzApiSrvLoginPrepare (): Promise<PzApiKratosResData> {
  const api = pzApi.auth.login.prepare

  return await doPzApiPrepareRequest<typeof api.reqData, typeof api.resData>(api, {})
}

// -------------------------------------------------------------------

export async function pzApiSrvLogin (data: PzApiAuthPostLoginReqData): Promise<{
  ok: boolean
  errMsg?: string
  details?: any
  identityId?: string
}> {
  const api = pzApi.auth.login.post
  try {
    const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, data)
    return {
      ok: resData.ok,
      identityId: resData.identityId,
      errMsg: resData.errMsg,
      details: resData.details // Forward any additional error details
    }
  } catch (error) {
    return {
      ok: false,
      errMsg: error instanceof Error ? error.message : 'Login request failed',
      details: error
    }
  }
}

// -------------------------------------------------------------------

export async function pzApiSrvRegistrationPrepare (): Promise<PzApiKratosResData> {
  const api = pzApi.auth.registration.prepare

  return await doPzApiPrepareRequest<typeof api.reqData, typeof api.resData>(api, {})
}

// -------------------------------------------------------------------

export async function pzApiSrvRegistration (
  data: PzApiAuthPostRegistrationReqData
): Promise<{
  ok: boolean
  errMsg?: string
  details?: any
  identityId?: string
}> {
  const api = pzApi.auth.registration.post
  try {
    const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, data)
    return {
      ok: resData.ok,
      identityId: resData.identityId,
      errMsg: resData.errMsg,
      details: resData.details
    }
  } catch (error) {
    return {
      ok: false,
      errMsg: error instanceof Error ? error.message : 'Registration request failed',
      details: error
    }
  }
}

// -------------------------------------------------------------------

export async function pzApiSrvGetTribes (userId: UuidB62): Promise<Tribe[]> {
  const api = pzApi.tribes.list
  const reqData = { userId, includeUsers: true }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  if (!resData.ok) {
    return []
  }

  return convertPzApiTribeToAppTribe(resData.tribes)
}

// -------------------------------------------------------------------

export async function pzApiSrvCreateTribeForUser (tribeId: UuidB62, name: string): Promise<Tribe[]> {
  const api = pzApi.tribes.insert
  const reqData = { tribeId, name }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  if (!resData.ok) {
    return []
  }

  return convertPzApiTribeToAppTribe(resData.tribes)
}

// -------------------------------------------------------------------

function convertPzApiTribeToAppTribe (data: pzApiTribe[] | null | undefined): Tribe[] {
  if (!data) {
    return []
  }

  return data.map((item: pzApiTribe) => {
    const users = item.users?.reduce((acc, user) => {
      acc[user.userId] = {
        id: user.userId,
        rights: user.rights,
        inviteeEmail: user.inviteeEmail
      }
      return acc
    }, {} as Record<UuidB62, { id: UuidB62, rights: string, inviteeEmail?: string }>) ?? {}

    return {
      id: item.id,
      name: item.name,
      users
    }
  })
}

// -------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/max-params
export async function pzApiSrvAddUserToTribe (
  tribeId: UuidB62,
  userId: UuidB62 | null,
  email: string,
  rights: 'r' | 'w' | 'p'
): Promise<{ ok: boolean, errMsg?: string }> {
  const api = pzApi.tribes.addUser
  const reqData = { tribeId, userId, email, rights }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  // console.log('pzApiSrvAddUserToTribe', resData)

  return {
    ok: resData.ok,
    errMsg: 'errMsg' in resData ? resData.errMsg : undefined
  }
}

// -------------------------------------------------------------------

export async function pzApiSrvUpdateUserRights (
  tribeId: UuidB62,
  userId: UuidB62,
  rights: 'r' | 'w'
): Promise<{ ok: boolean, errMsg?: string }> {
  const api = pzApi.tribes.updateUser
  const reqData = { tribeId, userId, rights }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  return {
    ok: resData.ok,
    errMsg: 'errMsg' in resData ? resData.errMsg : undefined
  }
}

// -------------------------------------------------------------------

export async function pzApiSrvGetUsersDetails (userIds: UuidB62[]): Promise<UserDetails[]> {
  const api = pzApi.users.details
  const reqData = { userIds }

  // log('pzApiSrvGetUsersDetails - requesting:', userIds)
  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)
  // log('pzApiSrvGetUsersDetails - response:', resData)

  if (!resData.ok || !resData.users) {
    throw new Error(
      'ok' in resData && !resData.ok && 'errMsg' in resData
        ? resData.errMsg
        : 'Failed to fetch user details'
    )
  }

  return resData.users
}

// -------------------------------------------------------------------

export async function pzApiSrvRespondToInvite (
  tribeId: UuidB62,
  accept: boolean
): Promise<{ ok: boolean, errMsg?: string }> {
  const api = pzApi.tribes.respondToInvite
  const reqData = { tribeId, accept }

  const resData = await doPzApiRequest<typeof api.reqData, typeof api.resData>(api, reqData)

  return {
    ok: resData.ok,
    errMsg: 'errMsg' in resData ? resData.errMsg : undefined
  }
}
