<script lang="ts">
import { t } from 'i18next'

import { appState } from '../store/appState'

import { updateAuthenticatedUserData } from '../store/auth'

// -------------------------------------------------------------------

let displayName = $state('')
let email = $state('')

$effect(() => {
  if (appState.authenticatedUser !== undefined) {
    displayName = appState.authenticatedUser.displayName
    email = appState.authenticatedUser.email
  }
})

// -------------------------------------------------------------------

const updateUserProfile = (event: Event): void => {
  event.preventDefault()

  if (appState.authenticatedUser !== undefined) {
    updateAuthenticatedUserData({
      id: appState.authenticatedUser.id,
      displayName: `${displayName}`.trim(),
      email: `${email}`.trim()
    })
  }
}
</script>

<!-- ------------------------------------------------------------- -->

<div>
  <h1>Profile ...</h1>
  <br />
  {#if appState.authenticatedUser}
    <div>
      Hello, {appState.authenticatedUser?.displayName}. Your email is {appState.authenticatedUser?.email}.
      <br />

      <form onsubmit={updateUserProfile}>
        <input class="input" placeholder={t('Name')} v-model="displayName" bind:value={displayName} />
        <br />

        <input class="input" placeholder={t('Email')} v-model="email" bind:value={email} />
        <br />

        <!-- :disabled="loading" -->
        <button className="m-3 text-sm btn">
          {t('Save')}
        </button>

        <!-- <div v-if="error">
          {{ error }}
        </div> -->
      </form>
    </div>
  {/if}
</div>
