/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { pzApiTribe } from '../../tribes'
import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiTribesSlug } from './tribesBaseApiDefs'

// -------------------------------------------------------------------

export interface PzApiTribesListReqData extends PzApiBaseReqData {
  userId?: string
  includeUsers?: boolean
}

// -------------------------------------------------------------------

export type PzApiTribesListResData =
  | (PzApiResDataSuccess & { tribes: pzApiTribe[] })
  | PzApiResDataFail

// -------------------------------------------------------------------

export const pzApiTribesListDef = {
  fetchUrl: `/${pzApiTribesSlug}/list`,
  fetchMethod: 'POST',
  reqData: {} as PzApiTribesListReqData,
  resData: {} as PzApiTribesListResData
} satisfies PzApiRoute<PzApiTribesListReqData, PzApiTribesListResData>
