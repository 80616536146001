import { appState } from '@/store/appState.svelte.ts'
import { updateSyncState } from '@/store/syncState.svelte.ts'
import { type DB, type DbMorph, dexieGetAllMorphs, dexieProcessSrvMorphs } from '@/utils/dexie.ts'
import { type UuidB62 } from '@/utils/id.ts'

import {
  type MorphSrvClient,
  morphSrvConvertSrvMorphsToDbMorphs,
  morphSrvGetSpaceMorphs,
  morphSrvSendSpaceMorphs } from './morphSrv.ts'
import { serverManager } from './serverManager.svelte.ts'
import { timerService } from './timerService.ts'

// -------------------------------------------------------------------

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    syncEnabled: boolean
    // eslint-disable-next-line no-unused-vars
    setSyncEnabled: (enabled: boolean) => void
  }
}

// -------------------------------------------------------------------

window.syncEnabled = true

// -------------------------------------------------------------------

window.setSyncEnabled = function (enabled: boolean): void {
  window.syncEnabled = enabled
}

// -------------------------------------------------------------------
// For sending morphs to server
// -------------------------------------------------------------------

export const PZ_SRV_SYNC_SEND_DELAY = 1000

// -------------------------------------------------------------------
// For polling morphs from server
// -------------------------------------------------------------------

const PZ_SRV_SYNC_POLL_INTERVAL = 500 // ms

// -------------------------------------------------------------------

const throttledSync = timerService.addThrottledTask(
  'morphSyncToServer',
  async (db: DB, morph: DbMorph) => {
    if (!window.syncEnabled) return

    try {
      updateSyncState({ isSyncing: true })
      const morphs = await dexieGetAllMorphs(db)
      await morphSrvSendSpaceMorphs(morph.spaceId as UuidB62, morphs)
    } finally {
      updateSyncState({ isSyncing: false })
    }
  },
  PZ_SRV_SYNC_SEND_DELAY
)

// -------------------------------------------------------------------

export function syncMorphToServer (db: DB, morph: DbMorph): void {
  if (!window.syncEnabled) return
  throttledSync(db, morph)
}

// -------------------------------------------------------------------

function getLocalHighestClocks (instance: typeof appState.instance): Record<string, number> {
  const clocks: Record<string, number> = {}

  if (instance === undefined) {
    return {}
  }

  for (const [key, instanceClock] of Object.entries(instance.clocks)) {
    clocks[key] = instanceClock.localHighestClock
  }

  return clocks
}

// -------------------------------------------------------------------

export function startMorphPolling (srv: MorphSrvClient, db: DB): void {
  // Create a unique ID for this polling task based on the first space ID
  const firstSpaceId = Object.keys(srv.spaces)[0]
  const pollTaskId = `morphPoll_${firstSpaceId}`

  // Remove any existing polling task for this server
  timerService.removeTask(pollTaskId)

  // Add new polling task
  timerService.addTask(pollTaskId, () => {
    if (!window.syncEnabled) return

    const tm = Date.now()
    for (const [spaceId, srvSpace] of Object.entries(srv.spaces)) {
      if (tm - srvSpace.lastPollTime > srvSpace.pollInterval) {
        srvSpace.lastPollTime = tm

        // Convert instanceClocks to an object with only the local highest clocks
        const clocks = appState.instance !== undefined
          ? getLocalHighestClocks(appState.instance)
          : {}

        void morphSrvGetSpaceMorphs(spaceId as UuidB62, clocks)
          .then((srvMorphs) => {
            const dbMorphs = morphSrvConvertSrvMorphsToDbMorphs(srvMorphs)
            return dexieProcessSrvMorphs(db, spaceId as UuidB62, dbMorphs)
          })
      }
    }
  }, PZ_SRV_SYNC_POLL_INTERVAL)

  // Start the timer service if it's not already running
  timerService.start()
}
