/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { UuidB62 } from '@utils/id'

import type { PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import { type PzApiBaseReqData, pzApiTribesSlug } from './tribesBaseApiDefs'

// -------------------------------------------------------------------

export interface PzApiTribesAddUserReqData extends PzApiBaseReqData {
  tribeId: UuidB62
  userId: UuidB62 | null
  email: string
  rights: 'w' | 'r' | 'p'
}

// -------------------------------------------------------------------

export type PzApiTribesAddUserResData =
  | (PzApiResDataSuccess & { added: boolean })
  | (PzApiResDataFail & { added: boolean })

// -------------------------------------------------------------------

export const pzApiTribesAddUserDef = {
  fetchUrl: `/${pzApiTribesSlug}/add_user`,
  fetchMethod: 'POST',
  reqData: {} as PzApiTribesAddUserReqData,
  resData: {} as PzApiTribesAddUserResData
} satisfies PzApiRoute<PzApiTribesAddUserReqData, PzApiTribesAddUserResData>
