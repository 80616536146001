<script lang="ts">
  import { t } from 'i18next'

  import { getSyncUIState } from '../store/syncState.svelte.ts'

  import { cn } from '../utils/shadcnUtils.ts'

  import AppSideMenu from './AppSideMenu.svelte'

  import ZIcon from './ZIcon.svelte'
  import NuIconMore from '@icons/NuIconMore.svelte'

  // -------------------------------------------------------------------

  let showDetails = $state(false)
  let sideMenuComponent = $state<AppSideMenu | undefined>(undefined)

  // Create reactive UI state
  const syncUIState = $derived(getSyncUIState())
</script>

<!-- ------------------------------------------------------------- -->

{#if syncUIState.color != ''}
  <div
    aria-label={syncUIState.message}
    role="status"
  >
    <button
      class="sync-status bg-pz_gray-darkestmid hover:bg-muted flex items-center rounded-full px-3 py-1.5 transition-colors"
      aria-label={t('Show sync details')}
      onclick={() => { showDetails = true }}
      onkeydown={(e: KeyboardEvent): void => {
        e.preventDefault()
        e.key === 'Enter' && (showDetails = true)
      }}
      type="button"
    >
        <div class={cn(
          'size-3 rounded-full transition-all duration-300 ease-in-out',
          {
            'bg-pz_dot_green': syncUIState.color === 'green',
            'bg-pz_dot_green_lite': syncUIState.color === 'green_lite',
            'bg-pz_dot_blue': syncUIState.color === 'blue',
            'bg-pz_dot_orange': syncUIState.color === 'orange',
            'bg-pz_dot_yellow': syncUIState.color === 'yellow',
            'bg-pz_dot_red': syncUIState.color === 'red'
          }
        )}></div>
        {#if syncUIState.exclaim}
          <span class={cn(
            'size-3 rounded-full text-[13px] font-bold flex items-center justify-center -mr-1',
            {
              'text-pz_dot_orange': syncUIState.color === 'orange',
              'text-pz_dot_red': syncUIState.color === 'red'
            }
          )}>
            !
          </span>
        {/if}
      <span class="text-pz_gray-darkmid ml-2 text-sm">{syncUIState.message}</span>
      <ZIcon
        class="!text-pz_gray-darkmid ml-0.5 size-4"
        icon={NuIconMore}
        onclick={() => {}}
      />
    </button>
  </div>
{/if}

{#if showDetails}
  <!-- TODO Make this good -->
  <AppSideMenu
    bind:this={sideMenuComponent}
    onClose={() => showDetails = false}
    title={t('Sync Status')}
  >
    <div class="flex flex-col gap-6 p-4">
      <!-- Current Status -->
      <div class="bg-muted rounded-lg p-4">
        <h3 class="mb-2 font-semibold">{t('Current Status')}</h3>
        <p>{syncUIState.message}</p>
      </div>

      <!-- Status Details -->
      <div class="flex flex-col gap-2">
        <div class="flex items-center justify-between rounded-md p-2">
          <span>{t('Local Storage')}</span>
          <span class={cn(
            'rounded-full px-2 py-1 text-xs',
            `bg-${syncUIState.color}/10 text-${syncUIState.color}`
          )}>
            {syncUIState.message}
          </span>
        </div>

        <div class="flex items-center justify-between rounded-md p-2">
          <span>{t('Cloud Sync')}</span>
          <span class={cn(
            'rounded-full px-2 py-1 text-xs',
            `bg-${syncUIState.color}/10 text-${syncUIState.color}`
          )}>
            {syncUIState.message}
          </span>
        </div>
      </div>

      <div class={cn(
        'rounded-lg p-4',
        `bg-${syncUIState.color}/10 text-${syncUIState.color}`
      )}>
        <h3 class="mb-2 font-semibold">{t('Error Details')}</h3>
        <p class="text-sm">{syncUIState.message}</p>
        <button
          class="mt-4 text-sm font-medium hover:underline"
          onclick={() => {/* TODO: Implement retry logic */}}
        >
          {t('Retry Sync')}
        </button>
      </div>

      <div class={cn(
        'rounded-lg p-4',
        `bg-${syncUIState.color}/10 text-${syncUIState.color}`
      )}>
        <h3 class="mb-2 font-semibold">{t('Anonymous Mode')}</h3>
        <p class="text-sm">{t('Your data is only stored locally on this device.')}</p>
        <button
          class="mt-4 text-sm font-medium hover:underline"
          onclick={() => {/* TODO: Enable registration flow */}}
        >
          {t('Register for Cloud Backup')}
        </button>
      </div>

      <!-- Help Text -->
      <div class="text-muted-foreground text-xs">
        <h4 class="mb-1 font-medium">{t('About Data Saving')}</h4>
        <p>{t('Changes are automatically saved locally and synced to the cloud when possible. No manual saving is required.')}</p>
      </div>
    </div>
  </AppSideMenu>
{/if}
