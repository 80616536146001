import { startEntityPolling } from '@/services/entitySync.ts'
import { initializeNetworkMonitoring } from '@/services/networkMonitor.ts'
import { timerService } from '@/services/timerService.ts'

import { cleanupExpiredPreferences } from './notifications.svelte.ts'

// -------------------------------------------------------------------

export function initializeServices (): void {
  // Start core services
  timerService.start()

  // Initialize monitoring and sync services
  initializeNetworkMonitoring()
  startEntityPolling()

  // Initialize notifications
  cleanupExpiredPreferences()
}
