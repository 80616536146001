import type { DbMorph } from '@/utils/dexie.ts'

import type { Instance } from './instance.svelte.ts'

// -------------------------------------------------------------------

export interface InstanceClock {
  localHighestClock: number
  confirmedServerClock: number
}

// -------------------------------------------------------------------

type ClockType = 'localHighestClock' | 'confirmedServerClock'

// -------------------------------------------------------------------

export function morphCheckAndChangeClock (instance: Instance, morph: DbMorph): void {
  if (morph.instanceClock <= instance.clocks[morph.instanceId]?.localHighestClock) {
    instance.clocks[morph.instanceId].localHighestClock++

    const epochSeconds = Math.floor(Date.now() / 1000)
    if (instance.clocks[morph.instanceId].localHighestClock < epochSeconds) {
      instance.clocks[morph.instanceId].localHighestClock = epochSeconds
    }

    morph.instanceClock = instance.clocks[morph.instanceId]?.localHighestClock
  }
}

// -------------------------------------------------------------------

function updateInstanceClocks (instance: Instance, morphs: DbMorph[], clockType: ClockType): void {
  for (const morph of morphs) {
    checkInstanceClocksExist(instance, morph.instanceId)

    if (morph.instanceClock > instance.clocks[morph.instanceId][clockType]) {
      instance.clocks[morph.instanceId][clockType] = morph.instanceClock
    }
  }
}

// -------------------------------------------------------------------

export function updateAllInstanceClocksSeen (instance: Instance, morphs: DbMorph[]): void {
  updateInstanceClocks(instance, morphs, 'localHighestClock')
}

// -------------------------------------------------------------------

export function updateAllInstanceClocksReceived (instance: Instance, morphs: DbMorph[]): void {
  updateAllInstanceClocksSeen(instance, morphs) // Update seen first
  updateInstanceClocks(instance, morphs, 'confirmedServerClock')
}

// -------------------------------------------------------------------

export function checkInstanceClocksExist (instance: Instance, instanceId: string): void {
  if (instance.clocks[instanceId] === undefined) {
    instance.clocks[instanceId] = {
      localHighestClock: -1,
      confirmedServerClock: -1
    }
  }
}
