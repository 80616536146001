import { t } from 'i18next'

import { appState } from './appState.svelte.ts'

import { gotoUrl } from '../routes.svelte.ts'

import { getSyncUIState } from './syncState.svelte.ts'

// -------------------------------------------------------------------

export type NotificationType = 'info' | 'warning' | 'error'

// -------------------------------------------------------------------

export interface AppNotification {
  id: string
  type: NotificationType
  title: string
  links?: Record<string, () => void>
  icon?: unknown
  onClick?: () => void
  onClose?: () => void
  allowHide?: boolean // New property to control if notification can be hidden
}

// -------------------------------------------------------------------

interface NotificationHidePreference {
  id: string
  hiddenUntil: number
}

// -------------------------------------------------------------------

const STORAGE_KEY = 'notification_preferences'

// -------------------------------------------------------------------

function getHiddenNotificationsRaw (): NotificationHidePreference[] {
  const stored = localStorage.getItem(STORAGE_KEY)
  return stored ? JSON.parse(stored) : []
}

// -------------------------------------------------------------------

export function cleanupExpiredPreferences (): void {
  const preferences = getHiddenNotificationsRaw()
  const now = Date.now()
  const active = preferences.filter(p => now < p.hiddenUntil)

  if (active.length < preferences.length) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(active))
  }
}

// -------------------------------------------------------------------

function getHiddenNotifications (): NotificationHidePreference[] {
  return getHiddenNotificationsRaw()
}

// -------------------------------------------------------------------

function isNotificationHidden (id: string): boolean {
  const preferences = getHiddenNotificationsRaw()
  const preference = preferences.find(p => p.id === id)
  if (!preference) return false
  return Date.now() < preference.hiddenUntil
}

// -------------------------------------------------------------------

export function hideNotification (id: string, days: number): void {
  const preferences = getHiddenNotifications()
  const hiddenUntil = Date.now() + (days * 24 * 60 * 60 * 1000)

  // Store preference
  const filtered = preferences.filter(p => p.id !== id)
  filtered.push({ id, hiddenUntil })
  localStorage.setItem(STORAGE_KEY, JSON.stringify(filtered))

  // Remove from current notifications
  const index = notifications.findIndex(n => n.id === id)
  if (index !== -1) {
    notifications.splice(index, 1)
  }
}

// -------------------------------------------------------------------

export const notifications = $state<AppNotification[]>([])

// -------------------------------------------------------------------

// Watch for anonymous user state changes
$effect.root(() => {
  $effect(() => {
    const syncUIState = getSyncUIState()
    if (appState.isAnonymousUser && syncUIState.color === 'orange') {
      // Check if notification is hidden before adding
      if (!isNotificationHidden('anonymous') && !notifications.some(n => n.id === 'anonymous')) {
        addNotification({
          id: 'anonymous',
          type: 'info',
          title: t('You are using the app as a guest. [Register](register) or [Login](login) to save your data and access all features.'),
          links: {
            register: () => gotoUrl('/register'),
            login: () => gotoUrl('/login')
          },
          allowHide: true
        })
      }
    } else {
      removeNotification('anonymous')
    }
  })
})

// -------------------------------------------------------------------

export function addNotification (notification: AppNotification): void {
  // Don't add if it's hidden
  if (notification.allowHide && isNotificationHidden(notification.id)) {
    return
  }

  // Don't add if already exists
  if (!notifications.some(n => n.id === notification.id)) {
    notifications.push(notification)
  }
}

// -------------------------------------------------------------------

export function removeNotification (id: string): void {
  const index = notifications.findIndex(n => n.id !== id)
  if (index !== -1) {
    notifications.splice(index, 1)
  }
}
