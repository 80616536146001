/* eslint-disable @typescript-eslint/consistent-type-assertions */

// -------------------------------------------------------------------

import type { PzApiResData, PzApiResDataFail, PzApiResDataSuccess, PzApiRoute } from '../apiDefs'

import type { PzApiKratosReqData } from './authBaseApiDefs'
import { pzApiAuthSlug } from './authBaseApiDefs'

// -------------------------------------------------------------------

const pzApiLogoutSlug = 'logout'

// -------------------------------------------------------------------

interface PzApiAuthLogoutResDataSuccess extends PzApiResDataSuccess {
  logoutToken: string
}

// -------------------------------------------------------------------

export type PzApiAuthLogoutResData = PzApiAuthLogoutResDataSuccess | PzApiResDataFail

// -------------------------------------------------------------------

export interface PzApiAuthPostLogoutData {
  logoutToken: string
}

// -------------------------------------------------------------------

export interface PzApiAuthPostLogoutReqData extends PzApiKratosReqData, PzApiAuthPostLogoutData {}

// -------------------------------------------------------------------

export const pzApiPrepareLogoutDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiLogoutSlug}`,
  fetchMethod: 'GET',
  includeContext: true,
  reqData: {} as PzApiKratosReqData,
  resData: {} as PzApiAuthLogoutResData
} satisfies PzApiRoute<PzApiKratosReqData, PzApiAuthLogoutResData>

// -------------------------------------------------------------------

export const pzApiPostLogoutDef = {
  fetchUrl: `/${pzApiAuthSlug}/${pzApiLogoutSlug}`,
  fetchMethod: 'POST',
  includeContext: true,
  reqData: {} as PzApiAuthPostLogoutReqData,
  resData: {} as PzApiResData
} satisfies PzApiRoute<PzApiAuthPostLogoutReqData, PzApiResData>
