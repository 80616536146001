import { log } from '@/utils/debug.ts'
import { newUuidB62, type UuidB62 } from '@/utils/id.ts'

import { appState, injectState, type UseStateReturn } from './appState.svelte.ts'

import { pzApiSrvCreateSpaceForUser, pzApiSrvGetSpaces } from '../services/pzApiSrv.ts'

import { processSpaceUsers } from './user.svelte.ts'

// -------------------------------------------------------------------

interface SpaceUser {
  id: UuidB62
  rights: string
}

export type SpaceUsers = Record<UuidB62, SpaceUser>

export interface Space {
  id: UuidB62
  name: string
  users: SpaceUsers
  // db?: SpaceDb
}

export type Spaces = Record<UuidB62, Space>

let spaces: Spaces = $state({})

// -------------------------------------------------------------------

export interface SpaceState {
  spaces: Spaces
  isSpacesKnown: boolean
  activeSpace: Space | undefined
}

// -------------------------------------------------------------------

export function createNewBasicSpace (userId: UuidB62, name = '_NEW_'): { id: UuidB62, name: string, userId: UuidB62 } {
  return { id: newUuidB62(), name, userId }
}

// -------------------------------------------------------------------

function createNewSpaceWithUsers (userId: UuidB62, name?: string | undefined): Space {
  const space = {
    ...createNewBasicSpace(userId, name),
    users: {
      [userId]: {
        id: userId,
        rights: 'owner'
      }
    }
  }

  spaces = {
    [space.id]: space
  }

  return space
}

// -------------------------------------------------------------------

function createLocalSpaceForAnonymousUser (userIdB62: UuidB62): void {
  createNewSpaceWithUsers(userIdB62)

  // Store the space in localStorage for persistence
  const localStorageKey = `anonymous_spaces_${userIdB62}`
  localStorage.setItem(localStorageKey, JSON.stringify(spaces))
}

// -------------------------------------------------------------------

function retrieveAnonymousSpaces (userIdB62: UuidB62): void {
  // For anonymous users, we store spaces in localStorage
  const localStorageKey = `anonymous_spaces_${userIdB62}`
  const storedSpaces = localStorage.getItem(localStorageKey)

  if (storedSpaces) {
    try {
      spaces = JSON.parse(storedSpaces)
    } catch (e) {
      log(0, 'Error parsing stored spaces, creating new one', e)
      createLocalSpaceForAnonymousUser(userIdB62)
    }
  } else {
    createLocalSpaceForAnonymousUser(userIdB62)
  }
}

// -------------------------------------------------------------------

async function retrieveRegisteredUserSpaces (userIdB62: UuidB62): Promise<void> {
  try {
    let spacesFromServer = await getAndUpdateSpacesFromServer(userIdB62)

    if (spacesFromServer.length <= 0) {
      // We got no spaces from the live db, which should mean there is not one yet for the user. We need to create one.
      spacesFromServer = await pzApiSrvCreateSpaceForUser(userIdB62)
      updateSpacesFromServerData(spacesFromServer)
    }

    if (spacesFromServer.length <= 0) {
      log(1, 'Error: no spaces after getting from live db or creating one in the live db.', spacesFromServer)
      // Create a local space as a fallback
      createNewSpaceWithUsers(userIdB62)
    }
  } catch (error) {
    log(0, 'Error retrieving or creating spaces:', error)

    // Create a local space as a fallback
    // createNewSpaceWithUsers(userIdB62)
  }
}

// -------------------------------------------------------------------

async function retrieveOrCreateSpaces (userIdB62: UuidB62): Promise<void> {
  // TODO Get from localStorage?

  if (appState.isAnonymousUser) {
    retrieveAnonymousSpaces(userIdB62)
  } else {
    await retrieveRegisteredUserSpaces(userIdB62)
  }
}

// -------------------------------------------------------------------

export function useSpaces (): UseStateReturn {
  let lastUserIdB62: UuidB62 | undefined

  return {
    start: (): ReturnType<UseStateReturn['start']> => {
      $effect.root(() => {
        $effect(() => {
          // Initialize spaces for both authenticated and anonymous users
          if (appState.isLoggedInAndIdKnown === true) {
            const userIdB62 = appState.authenticatedUserId
            if (userIdB62 !== undefined && userIdB62 !== lastUserIdB62) {
              lastUserIdB62 = userIdB62

              // TODO Create tests.

              void retrieveOrCreateSpaces(userIdB62)
            }
          }
        })
      })
    },

    extendState (): ReturnType<UseStateReturn['extendState']> {
      injectState('spaces', () => spaces)
      injectState('isSpacesKnown', () => Object.keys(spaces).length > 0)
      injectState('activeSpace', () => {
        const spaceValues = Object.values(spaces)
        return spaceValues.length > 0 ? spaceValues[0] : undefined
      })
    }
  }
}

// -------------------------------------------------------------------

function updateSpacesFromServerData (spacesFromServer: Space[]): void {
  if (spacesFromServer.length > 0) {
    // We got spaces from the pzApiSrv. We need to merge it with local storage.
    // TODO Sync spaces with localStorage?
    spaces = Object.fromEntries(spacesFromServer.map(space => [space.id, space]))
    spacesFromServer.forEach(space => processSpaceUsers(space.users))
  }
}

// -------------------------------------------------------------------

async function getAndUpdateSpacesFromServer (userId: UuidB62): Promise<Space[]> {
  const spacesFromServer = await pzApiSrvGetSpaces(userId)
  updateSpacesFromServerData(spacesFromServer)
  return spacesFromServer
}

// -------------------------------------------------------------------

export async function syncSpacesFromServer (userId: UuidB62): Promise<void> {
  await getAndUpdateSpacesFromServer(userId)
}
