<script lang="ts">
  import { t } from 'i18next'

  import { appState } from '../store/appState.svelte.ts'

  import LogPreview from '../components/LogPreview.svelte'

  // -------------------------------------------------------------------

  let debugLogs = $state<string[]>([])

  $effect(() => {
    debugLogs = window.pzDebugLogs || []
  })
</script>

<!-- ------------------------------------------------------------- -->

<div class="p-4">
  <h1 class="mb-6 text-3xl font-bold">{t('Debug Information')}</h1>

  <div class="space-y-4">
    <LogPreview dat={appState.tribes} title="Tribes" />
    <LogPreview dat={appState.spaces} title="Spaces" />
    <LogPreview dat={appState.users} title="Users" />

    <LogPreview dat={debugLogs} title="Debug Logs" />
  </div>
</div>
