import type { UuidB62 } from '@utils/id'

// -------------------------------------------------------------------

export const pzApiTribesSlug = 'tribes'

// -------------------------------------------------------------------

export interface PzApiBaseReqData {
  authenticatedUserId?: UuidB62
}
