// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register'

import { timerService } from '@/services/timerService.ts'

import { getHabitat } from './env.ts'

// -------------------------------------------------------------------

const intervalMS = 60 * 60 * 1000
// const intervalMS = 1 * 60 * 1000

// -------------------------------------------------------------------

async function pwaIntervalCheck (swUrl: string, r: ServiceWorkerRegistration): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!(!r.installing && navigator)) {
    return
  }

  // console.log('SERVICEWORKER: onRegisteredSW 222')

  if ('connection' in navigator && !navigator.onLine) {
    return
  }

  // console.log('SERVICEWORKER: onRegisteredSW 333')

  // Force the browser to check for updates of the swUrl file (which seems to be always sw.js). Make sure this file is not cached, so send it with the correct caching headers.
  const resp = await fetch(swUrl, {
    cache: 'no-store',
    headers: { cache: 'no-store', 'cache-control': 'no-cache' }
  })

  // console.log('SERVICEWORKER: onRegisteredSW 444', resp)

  if (resp?.status === 200) {
    // console.log('SERVICEWORKER: onRegisteredSW 555')

    // Tell the new serviceworker to take control immediately. Not sure there is always a new serviceworker.
    // This will NOT refresh the page, but if the serviceworker is new or detects changes, it will trigger the onNeedRefresh function below.
    await r.update()
  }
}

// -------------------------------------------------------------------

export function usePwa (): void {
  const habitat = getHabitat()

  if ('serviceWorker' in navigator && !habitat.dev) {
    const updateSW = registerSW({
      onRegisteredSW (swUrl: string, r: ServiceWorkerRegistration | undefined) {
        //
        // Every hour, check for app updates, so the user can be notified.
        // See: https://vite-pwa-org.netlify.app/guide/periodic-sw-updates
        //
        r !== undefined &&
          timerService.addTask('pwaUpdateCheck', () => {
            void pwaIntervalCheck(swUrl, r)
          }, intervalMS)
      },

      onNeedRefresh () {
        console.log('SERVICEWORKER: new version. Reload.')
        // addToAlerts({
        //   // title: t('_new_version_available_click_to_reload_'),
        //   title: t('A new version is available. Click here to reload and benefit.'),
        //   onClick: () => {
        //     updateSW(true)
        //   }
        // })

        // The serviceworker detected a new version. Ask the user if he wants to reload the app, so the new serviceworker is activated and new version files are loaded.
        if (window.confirm('Load new app version?')) {
          void updateSW(true)
        }
      },

      onOfflineReady () {
        console.log('SERVICEWORKER: onOfflineReady.')
      }
    })
    // console.log('SERVICEWORKER registered. 37')
  }
}
