import { getMorphSrvForSpace, morphSrvGetSpaceMorphs, type SrvMorph } from '@/services/morphSrv.ts'
import { PZ_SRV_SYNC_SEND_DELAY, syncMorphToServer } from '@/services/morphSync.ts'
import { appState } from '@/store/appState.svelte.ts'
import { base64ToBytes } from '@/utils/base64.ts'
import { log } from '@/utils/debug.ts'
import { type DbMorph, dexieAddMorph, dexieClearDb, dexieGetAllMorphs } from '@/utils/dexie.ts'
import { newUuidB62, type UuidB62 } from '@/utils/id.ts'
import { type YDB } from '@/utils/ydb.ts'

// -------------------------------------------------------------------

const ERROR = 'ERROR'
const SPACE = '0000000000000000000123' as UuidB62
const API_BASE = `${getMorphSrvForSpace(SPACE).url}/test/sync`
const TEST_BREED = 'test'
const TEST_KEY = 'name'

// -------------------------------------------------------------------

type TestFunction = () => Promise<string>

// -------------------------------------------------------------------

async function syncTestCreateClockConflict (): Promise<string> {
  const response = await fetch(`${API_BASE}/create-clock-conflict`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })

  const responseJson = await response.json()
  return responseJson.ok ? 'syncTestCreateClockConflict' : ERROR + JSON.stringify(responseJson)
}

// -------------------------------------------------------------------

async function syncTestVerifyClockAdjustment (): Promise<string> {
  // Verify that client clock was adjusted correctly
  const response = await fetch(`${API_BASE}/verify-clock-adjustment`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })

  const responseJson = await response.json()
  return responseJson.ok ? 'syncTestVerifyClockAdjustment' : ERROR + JSON.stringify(responseJson)
}

// -------------------------------------------------------------------

async function resetServerDatabase (): Promise<string> {
  const response = await fetch(`${API_BASE}/reset-server-morph-db`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })

  const responseJson = await response.json()
  return responseJson.ok ? '' : ERROR + JSON.stringify(responseJson)
}

// -------------------------------------------------------------------

async function verifyServerDbEmpty (): Promise<string> {
  const response = await fetch(`${API_BASE}/verify-empty-db`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })
  const responseJson = await response.json()
  return responseJson.ok ? '' : ERROR + 'Server DB not empty: ' + JSON.stringify(responseJson)
}

// -------------------------------------------------------------------

async function verifyLocalDbEmpty (db: YDB): Promise<string> {
  try {
    const localMorphs = await dexieGetAllMorphs(db.db)
    return localMorphs.length === 0 ? '' : ERROR + 'Local DB not empty: ' + localMorphs.length + ' morphs found'
  } catch (err) {
    log(0, 'verifyLocalDbEmpty error:', err)
    return ERROR + String(err)
  }
}

// -------------------------------------------------------------------

async function clearAndVerifyDatabases (userDb: YDB): Promise<string> {
  // Reset and verify server DB
  const serverResetResult = await resetServerDatabase()
  if (serverResetResult) return serverResetResult

  // Clear and verify local DB
  await dexieClearDb(userDb.db)

  // Verify both DBs are empty
  const serverVerifyResult = await verifyServerDbEmpty()
  if (serverVerifyResult) return serverVerifyResult

  const localVerifyResult = await verifyLocalDbEmpty(userDb)
  if (localVerifyResult) return localVerifyResult

  return ''
}

// -------------------------------------------------------------------

async function syncTestEmptyLocalAndServerDbs (): Promise<string> {
  const prevSyncEnabled = window.syncEnabled
  window.setSyncEnabled(false)

  try {
    if (!appState.userDb) return ERROR + 'No UserDB available'

    const result = await clearAndVerifyDatabases(appState.userDb)
    return result || 'syncTestEmptyLocalAndServerDbs'
  } catch (err) {
    log(0, 'syncTestEmptyLocalAndServerDbs error:', err)
    return ERROR + String(err)
  } finally {
    window.setSyncEnabled(prevSyncEnabled)
  }
}

// -------------------------------------------------------------------

const tests: Record<string, TestFunction> = {
  syncTestCreateItem,
  syncTestEmptyLocalAndServerDbs,
  syncTestVerifyResync,
  syncTestCreateClockConflict,
  syncTestVerifyClockAdjustment,
  syncTestNewInstanceInit
}

// -------------------------------------------------------------------

export async function syncRunTest (tst: string): Promise<string> {
  console.log('Running sync test:', tst)
  const testFunc = tests[tst]
  if (!testFunc) {
    log(0, 'Test not found:', tst)
    return ERROR
  }

  try {
    return await testFunc()
  } catch (err) {
    log(0, 'Test error:', err)
    return ERROR
  }
}

// -------------------------------------------------------------------

function verifyMorphInDb (morphs: DbMorph[]): boolean {
  return morphs.some(m =>
    m.breed === TEST_BREED &&
    m.ky === TEST_KEY &&
    m.spaceId === SPACE
  )
}

// -------------------------------------------------------------------

async function createAndSaveMorph (userDb: YDB): Promise<DbMorph> {
  const testMorph: DbMorph = {
    id: newUuidB62(),
    instanceId: appState.instance?.id ?? '',
    spaceId: SPACE,
    itemId: newUuidB62(),
    breed: TEST_BREED,
    ky: TEST_KEY,
    instanceClock: 1,
    ymut: new Uint8Array([1, 2, 3])
  }

  await dexieAddMorph(userDb.db, testMorph)
  syncMorphToServer(userDb.db, testMorph)

  // Wait for sync delay
  await new Promise(resolve => setTimeout(resolve, PZ_SRV_SYNC_SEND_DELAY + 100))
  return testMorph
}

// -------------------------------------------------------------------

async function verifyMorphsInDatabases (localMorphs: DbMorph[], serverMorphs: SrvMorph[]): Promise<string> {
  if (!await verifyMorphInDb(localMorphs)) {
    return ERROR + 'Test morph not found in local database'
  }

  const convertedServerMorphs = serverMorphs.map(morph => ({
    ...morph,
    ymut: base64ToBytes(morph.ymut)
  }))

  if (!await verifyMorphInDb(convertedServerMorphs)) {
    return ERROR + 'Test morph not found in server database'
  }

  return ''
}

// -------------------------------------------------------------------

async function syncTestCreateItem (): Promise<string> {
  if (!appState.userDb) return ERROR + 'No UserDB available'

  try {
    await createAndSaveMorph(appState.userDb)

    const [localMorphs, serverMorphs] = await Promise.all([
      dexieGetAllMorphs(appState.userDb.db),
      morphSrvGetSpaceMorphs(SPACE, {})
    ])

    const verifyResult = await verifyMorphsInDatabases(localMorphs, serverMorphs)
    if (verifyResult) return verifyResult

    return 'syncTestCreateItem'
  } catch (err) {
    log(0, 'syncTestCreateItem error:', err)
    return ERROR + String(err)
  }
}

// -------------------------------------------------------------------

async function syncTestVerifyResync (): Promise<string> {
  const response = await fetch(`${API_BASE}/verify-resync`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })

  const responseJson = await response.json()
  return responseJson.ok ? 'syncTestVerifyResync' : ERROR + JSON.stringify(responseJson)
}

// -------------------------------------------------------------------

async function syncTestNewInstanceInit (): Promise<string> {
  const response = await fetch(`${API_BASE}/verify-instance-init`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ spaceId: SPACE })
  })

  const responseJson = await response.json()
  return responseJson.ok ? 'syncTestNewInstanceInit' : ERROR + JSON.stringify(responseJson)
}
