<script lang="ts">
  import type { Component, Snippet } from 'svelte'

  import { teleport } from '@/utils/teleport.ts'

  import ZIcon from './ZIcon.svelte'

  // -------------------------------------------------------------------

  const {
    title = 'Peanutz',
    icon,
    children
  }: {
    title?: string
    icon?: Component
    children?: Snippet
  } = $props()
</script>

<!-- ------------------------------------------------------------- -->

<div use:teleport={'app-header-title'}>
  {#if children}
    {@render children()}
  {:else}
    <div class="flex items-center gap-3">
      {#if icon}
        <ZIcon class="size-6" {icon} />
      {/if}
      <h1 class="flex translate-y-[2px] items-center">
        {title}
      </h1>
    </div>
  {/if}
</div>
